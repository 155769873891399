import React from 'react';

import styles from './Button.css';
import BrowserRouter from '../libs/BrowserRouter';
import router from '../streams/router';
import Icon from './Icon';


const MAILTO_REGEX = /^mailto:/;
const ROUTE_LINK_REGEX = /^\//;

let browserRouter = new BrowserRouter(router);

export interface ButtonProps {
	id?: string;
	name?: string;
	type?: 'flat' | 'invisible';
	size?: string;
	color?: string;
	link?: string;
	label?: string;
	overlayLabel?: string;
	overlayDisabledLabel?: string;
	badge?: string;
	icon?: string;
	selectedIcon?: string;
	iconAfter?: string;
	selectedIconAfter?: string;
	isSelected?: boolean;
	isDisabled?: boolean;
	isSubmit?: boolean;
	useRouter?: boolean;
	handleClick: () => any;
}

export default class Button extends React.Component<ButtonProps, {}> {
	render() {
		let buttonClass = styles.full;

		if (this.props && this.props.type === 'floating' as string) {
			buttonClass = styles.floating;
		} else if (this.props && this.props.type === 'outlined' as string) {
			buttonClass = styles.outlined;
		} else if (this.props && this.props.type === 'invisible' as string) {
			buttonClass = styles.invisible;
		}

		if (this.props && this.props.size === 'small') {
			buttonClass += ` ${styles.isSmall}`;
		} else if (this.props && this.props.size === 'large') {
			buttonClass += ` ${styles.isLarge}`;
		}

		if (this.props && this.props.color === 'secondary') {
			buttonClass += ` ${styles.isSecondary}`;
		} else if (this.props && this.props.color === 'tertiary') {
			buttonClass += ` ${styles.isTertiary}`;
		} else if (this.props && this.props.color === 'inverse') {
			buttonClass += ` ${styles.isInverse}`;
		}

		buttonClass += this.props && this.props.isSelected ? ' isSelected' : '';

		buttonClass += this.props && (this.props.icon || this.props.selectedIcon) ? ' hasIcon' : '';
		buttonClass += this.props && this.props.selectedIcon ? ' hasSelectableIcon' : '';
		buttonClass += this.props && (this.props.iconAfter || this.props.selectedIconAfter) ? ' hasIconAfter' : '';
		buttonClass += this.props && (this.props.icon || this.props.selectedIcon || this.props.iconAfter || this.props.selectedIconAfter) && !this.props.label && !this.props.badge ? ' hasOnlyIcon' : '';

		let iconElements: Array<Element> = [];

		if (this.props && this.props.icon) {
			iconElements.push(this.props && this.props.type === 'floating' as string ? <Icon key="icon" size={this.props && this.props.size === 'large' ? 'large' : 'normal'} id={this.props.icon} /> : <Icon key="icon" size={this.props && this.props.size === 'large' ? 'medium' : 'normal'} id={this.props.icon} />);
		}

		if (this.props && this.props.selectedIcon) {
			iconElements.push(<Icon key="selectedIcon" id={this.props.selectedIcon} />);
		}

		let iconAfterElements: Array<Element> = [];

		if (this.props && this.props.iconAfter) {
			iconAfterElements.push(<Icon key="iconAfter" id={this.props.iconAfter} />);
		}

		if (this.props && this.props.selectedIconAfter) {
			iconAfterElements.push(<Icon key="selectedIconAfter" id={this.props.selectedIconAfter} />);
		}

		let buttonElement = <button
			className={styles.root}
			title={this.props.label}
			onClick={this.handleClick}><span className={buttonClass + (this.props && this.props.isDisabled ? ` ${styles.isDisabled}` : ` ${styles.isEnabled}`)}>
			<span className={styles.icon}>{iconElements}</span>
			{this.props && this.props.label ? <span className={styles.label}>{this.props.label}</span> : null}
			{this.props && this.props.badge ? <span className={styles.badge}>{this.props.badge}</span> : null}
			<span className={styles.iconAfter}>{iconAfterElements}</span>
		</span>
			{this.props && this.props.overlayLabel && (!this.props.isDisabled && this.props.overlayDisabledLabel || !this.props.overlayDisabledLabel) ? <span className={styles.overlay}>{this.props.overlayLabel}</span> : null}
			{this.props && this.props.isDisabled && this.props.overlayDisabledLabel ? <span className={styles.overlay}>{this.props.overlayDisabledLabel}</span> : null}
		</button>;

		if (this.props && this.props.isSubmit) {
			buttonElement = <input
				className={buttonClass + (this.props.isDisabled ? ` ${styles.isDisabled}` : ` ${styles.isEnabled}`)}
				type="submit"
				name={this.props.name || this.props.id || ''}
				id={this.props.id || this.props.name || ''}
				value={this.props.label || ''}
				onClick={this.handleClick}
			/>;
		} else if (this.props && this.props.link) {
			buttonElement = <a
				className={buttonClass + (this.props.isDisabled ? ' isDisabled' : ' isEnabled')}
				title={this.props.label}
				href={this.props.link ? this.props.link : '#'}
				target={!this.props.isDisabled && this.props.link && ROUTE_LINK_REGEX.test(this.props.link) && this.props.useRouter !== false ? '' : '_blank'}
				onClick={this.handleClick}>
				<span className={styles.icon}>{iconElements}</span>
				{this.props.label || null}
				{this.props.badge ? <span className={styles.badge}>{this.props.badge}</span> : null}
				<span className={styles.iconAfter}>{iconAfterElements}</span>
			</a>;
		}

		return buttonElement;
	}

	handleClick = (event) => {
		if (this.props && !this.props.isDisabled && !(this.props.link || (this.props.link && MAILTO_REGEX.test(this.props.link)))) {
			event.preventDefault();

			if (this.props.handleClick) {
				this.props.handleClick();
			}
		} else if (this.props && !this.props.isDisabled && this.props.link && ROUTE_LINK_REGEX.test(this.props.link) && this.props.useRouter !== false) {
			event.preventDefault();

			browserRouter.navigate(this.props.link);
		} else if (this.props && this.props.isDisabled) {
			event.preventDefault();
		}
	};
}
