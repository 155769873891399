// import Inferno from 'inferno';
// import Component from 'inferno-component';
import React from 'react';

import styles from './MaterialStep.css';
import openStepActionStream from '../streams/openStepActionStream';
import dataStream from '../streams/dataStream';
import orderStream from '../streams/orderStream';
import {Material} from '../types/data';
import selectMaterialActionStream from '../streams/selectMaterialActionStream';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import link from '../utils/link';
import getOrderItemPrice from '../utils/getOrderItemPrice';
import Stream from '../libs/Stream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import getConvertedPrice from '../utils/getConvertedPrice';
import config from '../config';
import selectProfileActionStream from '../streams/selectProfileActionStream';
import createOrderItemActionStream from '../streams/createOrderItemActionStream';
import selectWindowActionStream from '../streams/selectWindowActionStream';
import destroyOrderItemActionStream from '../streams/destroyOrderItemActionStream';
import _ from 'lodash';

const {VAT_MODIFIER} = config;

let i18n = new I18n();

export interface MaterialDetailProps {
	material: Material;
	isSelected: boolean;
	handleClick: (material: Material) => Material;
}

export class MaterialDetail extends React.Component<MaterialDetailProps, {}> {
	render() {
		let order = orderStream.value;
		let orderItem;

		if (order?.currentItem !== null) {
			orderItem = order.items[order.currentItem];
		}

		if (!this.props) {
			return;
		}

		// let {windowPrice: currentWindowPrice} = getOrderItemPrice(orderItem);
		// let {windowPrice: nextWindowPrice} = getOrderItemPrice(
		// 	{
		// 		...orderItem,
		// 		material: {
		// 			id: this.props.material.id,
		// 			name: this.props.material.name
		// 		}
		// 	}
		// ); 
		// let delta = getConvertedPrice((nextWindowPrice - currentWindowPrice) * VAT_MODIFIER[languageStream.value.current]);
		// console.log("delta", delta);
		// console.log("orderItem", orderItem);

		return (
			<div className={styles.materialDetail + (this.props.isSelected ? ` ${styles.isSelected}` : '')} onClick={this.handleClick}>
				<figure className={styles.materialDetailPhoto}>
					<img src={link('assets', this.props.material.image)} alt={this.props.material.name[languageStream.value.current]} />
					{/* <figcaption className={styles.materialDetailTitle}>{this.props.material.name[languageStream.value.current]}</figcaption> */}
				</figure>
				<h4 className={styles.materialDetailHeading}>{this.props.material.name[languageStream.value.current]}</h4>
			</div>
		);
	}

	handleClick = () => {
		this.props.handleClick(this.props.material);
	};
}

export interface MaterialStepProps {
	isOpen: boolean;
}

export default class MaterialStep extends React.Component<MaterialStepProps, {}> {
	onLanguageStream: Stream<{}>;

	constructor(props) {
		super(props);
		this.state = {
			selectedMaterialType: dataStream?.value?.material || null
		};
	}
	
	componentDidMount() {
		this.onLanguageStream = languageStream.on(() => {
			// console.log('MaterialStep 1');
			// requestAnimationFrame(() => {
			//	 console.log('MaterialStep 2');
			this.forceUpdate();
			// });
		});
	}

	componentWillUnmount() {
		this.onLanguageStream.end.push(true);
	}
	
	render() {
		let {materials} = dataStream.value;
		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		
		/* if (!orderItem) {
			return null;
		}
	*/
		return (
			<section className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}>
				<div className={styles.wrapper}>
					<h2 className={styles.heading}>{i18n.translate `MaterialStep - heading 1`}</h2>
					<span className={styles.closeButton}>
						<Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick} />
					</span>

					<div className={styles.material}>
						<ul className={styles.materialsList}>
							{materials.map((material, index) => <li key={index}>
								<MaterialDetail
									material={material}
									isSelected={this.isSelectedMaterial(orderItem, material)}
									handleClick={this.handleSelectMaterial}
								/>
							</li>
							)}
						</ul>

						{/* <p className={styles.warning}>{i18n.translate `MaterialStep - warning`}</p> */}

						<ButtonGroup align="center">
							<Button type="flat" size="large"
								label={i18n.translate `MaterialStep - button 1`}
								isDisabled={!this.state.selectedMaterialType}
								handleClick={() => this.handleCloseClick(true)}
							/>
						</ButtonGroup>
					</div>
				</div>
			</section>
		);
	}

	handleCloseClick = (saveChange = false) => {
		if (!saveChange) { return openStepActionStream.push(null); }

		let order = orderStream.value;
		if (!order.items.length) {
			createOrderItemActionStream.push(true);
		}
		
		let orderItem;
		if (order.currentItem !== null) {
			orderItem = order.items[order.currentItem];
		}
		
		const material = this.state.selectedMaterialType;
		if (_.isFinite(orderItem?.material?.id) && orderItem?.material?.id !== material?.id) {
			destroyOrderItemActionStream.push(order.currentItem);
			createOrderItemActionStream.push(true);
		}
	
		selectMaterialActionStream.push({
			id: material.id,
			name: material.name
		});
		
		// if(orderItem && orderItem.window) {
		let {profiles} = dataStream.value;
		const selectedProfile = profiles.find((profile) => profile.materialIds.includes(material.id));
		selectProfileActionStream.push({
			id: selectedProfile.id,
			name: selectedProfile.name,
			priceModifier: selectedProfile.priceModifier,
			rebate: selectedProfile.rebate
		});
		// }
		
		this.setState({selectedMaterialType: material});
		openStepActionStream.push('window');
	};

	isSelectedMaterial = (orderItem, actualMaterial) => {
		const material = this.state.selectedMaterialType;
		if (material) { return material.id === actualMaterial.id; }
		if (orderItem?.material?.id) { return actualMaterial.id === orderItem?.material?.id; }

		return false;
	};

	handleSelectMaterial = (material) => {
		this.setState({selectedMaterialType: material});
	};
}
