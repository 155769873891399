import Stream from '../libs/Stream';


export interface SelectGlassAction {
	id: number;
	name: string;
	price: number;
}

let selectGlassActionStream: Stream<SelectGlassAction> = new Stream() as Stream<SelectGlassAction>;

export default selectGlassActionStream;
