import {useState, useEffect} from 'react';

export function useStream(stream) {
	let [value, update] = useState(stream.value);

	useEffect(() => {
		let subscriptionStream = stream.subscribe((newValue) => {
			update(newValue);
		});

		return () => {
			subscriptionStream.end.push(true);
		};
	}, [stream]);

	return value;
}
