import constants from './constants';


const {CLIENT_PLATFORM} = constants;
const {SERVER_PLATFORM} = constants;


/**
 * An object environment feature flags.
 */
let support: {
	platform?: string
} = {};


/**
 * Returns string based on the platform (ie. server or client) ash is running on.
 */
support.platform = typeof exports !== 'undefined' && typeof global.process === 'object' ? SERVER_PLATFORM : CLIENT_PLATFORM;

export default support;
