let breakpoints: any = {
	tinyPage: {
		start: 1,
		end: 640
	},
	compactPage: {
		start: 1,
		end: 1024
	}
};

export default breakpoints;
