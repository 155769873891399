import Stream from '../libs/Stream';


export interface SelectWindowAction {
	typeId: number;
	sizeId: number;
	type: string;
	materialId: number;
	profileId: number;
	width: number;
	height: number;
	price: number;
	hasLeftRightVariants: boolean;
	variant: 1 | 2;
}

let selectWindowActionStream: Stream<SelectWindowAction> = new Stream() as Stream<SelectWindowAction>;

export default selectWindowActionStream;
