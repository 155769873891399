import _ from 'lodash';
import {Profile, Profiles, WindowType} from '../types/data';

export default function findDefaultProfile(profiles: Profiles, windowType?: WindowType, materialId ?: number): Profile {
	let filteredProfile = profiles.find((profile) => profile.materialIds.includes(materialId));
	
	if (windowType && _.isFinite(windowType.profileId)) {
		let result = profiles.find((profile) => profile.materialIds.includes(materialId) && profile.id === windowType.profileId);
		if (result) {
			return _.cloneDeep(result);
		}
		
		return _.cloneDeep(filteredProfile);
	}

	return _.cloneDeep(filteredProfile);
}
