/* eslint-disable complexity, max-classes-per-file */

import _ from 'lodash';
import React from 'react';
import $ from 'jquery';

import styles from './AccessoriesStep.css';
import Input from './Input';
import Select from './Select';
import Button from './Button';
import dataStream from '../streams/dataStream';
import orderStream from '../streams/orderStream';
import selectAccessoriesActionStream from '../streams/selectAccessoriesActionStream';
import {InnerWindowsillColor, OuterWindowsillColor, SunblindColor, Accessory} from '../types/data';
import openStepActionStream from '../streams/openStepActionStream';
import ButtonGroup from './ButtonGroup';
import link from '../utils/link';
import Stream from '../libs/Stream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import getConvertedPrice from '../utils/getConvertedPrice';
import config from '../config';

const VAT_MODIFIER = config.VAT_MODIFIER; // eslint-disable-line prefer-destructuring
const SUNBLIND_MARGIN = config.SUNBLIND_MARGIN; // eslint-disable-line prefer-destructuring
const MM_IN_M = 1000;

let i18n = new I18n();

function getClosestNumber(numbers: Array<number>, value: number): number {
	let [result] = numbers;
	let delta = Math.abs(value - result);
	
	for (let i = 0; i < numbers.length; i++) {
		let newDelta = Math.abs(value - numbers[i]);
		
		if (newDelta < delta) {
			delta = newDelta;
			result = numbers[i];
		}
	}
	
	return result;
}

export interface SunblindColorDetailProps {
	isSelected: boolean;
	sunblindColor: SunblindColor;
	handleClick: (sunblindColor: SunblindColor) => void;
}

export class SunblindColorDetail extends React.Component<SunblindColorDetailProps, {}> {
	render() {
		if (!this.props) {
			return null;
		}
		
		return (
			<div className={styles.sunblindColorDetail + (this.props.isSelected ? ` ${styles.isSelected}` : '')} onClick={this.handleClick}>
				<figure className={styles.sunblindColorDetailPreview}>
					{this.props.sunblindColor.thumb ? <img src={link('assets', this.props.sunblindColor.thumb)} alt={this.props.sunblindColor.name[languageStream.value.current]} /> : null}
				</figure>
				<div className={styles.sunblindColorDetailOverlay}>
					<p className={styles.sunblindColorDetailOverlayHeading}>
						{this.props.sunblindColor.name[languageStream.value.current]}
					</p>
					{this.props.sunblindColor.image ? (
						<figure className={styles.sunblindColorDetailOverlayPhoto}>
							<img src={link('assets', this.props.sunblindColor.image)} alt={this.props.sunblindColor.name[languageStream.value.current]} />
						</figure>
					) : null}
				</div>
			</div>
		);
	}
	
	handleClick = (event) => {
		if (this.props) {
			this.props.handleClick(this.props.sunblindColor);
		}
	};
}

export interface InnerWindowsillColorDetailProps {
	isSelected: boolean;
	innerWindowsillColor: InnerWindowsillColor;
	handleClick: (innerWindowsillColor: InnerWindowsillColor) => void;
}

export class InnerWindowsillColorDetail extends React.Component<InnerWindowsillColorDetailProps, {}> {
	render() {
		if (!this.props) {
			return null;
		}
		
		return (
			<div className={styles.innerWindowsillColorDetail + (this.props.isSelected ? ` ${styles.isSelected}` : '')} onClick={this.handleClick}>
				<figure className={styles.innerWindowsillColorDetailPreview}>
					{this.props.innerWindowsillColor.image ? (
						<img src={link('assets', this.props.innerWindowsillColor.image)} alt="" />
					) : null}
				</figure>
				<div className={styles.innerWindowsillColorDetailOverlay}>
					<p className={styles.innerWindowsillColorDetailOverlayHeading}>
						{this.props.innerWindowsillColor.name[languageStream.value.current]}
					</p>
					{this.props.innerWindowsillColor.image ? (
						<figure className={styles.innerWindowsillColorDetailOverlayPhoto}>
							<img src={link('assets', this.props.innerWindowsillColor.image)} alt="" />
						</figure>
					) : null}
				</div>
			</div>
		);
	}
	
	handleClick = (event) => {
		if (this.props) {
			this.props.handleClick(this.props.innerWindowsillColor);
		}
	};
}

export interface OuterWindowsillColorDetailProps {
	isSelected: boolean;
	outerWindowsillColor: OuterWindowsillColor;
	handleClick: (outerWindowsillColor: OuterWindowsillColor) => void;
}

export class OuterWindowsillColorDetail extends React.Component<OuterWindowsillColorDetailProps, {}> {
	render() {
		if (!this.props) {
			return null;
		}
		
		return (
			<div className={styles.outerWindowsillColorDetail + (this.props.isSelected ? ` ${styles.isSelected}` : '')} onClick={this.handleClick}>
				<figure className={styles.outerWindowsillColorDetailPreview}>
					{this.props.outerWindowsillColor.image ? (
						<img src={link('assets', this.props.outerWindowsillColor.image)} alt="" />
					) : null}
				</figure>
				<div className={styles.outerWindowsillColorDetailOverlay}>
					<p className={styles.outerWindowsillColorDetailOverlayHeading}>
						{this.props.outerWindowsillColor.name[languageStream.value.current]}
					</p>
					{this.props.outerWindowsillColor.image ? (
						<figure className={styles.outerWindowsillColorDetailOverlayPhoto}>
							<img src={link('assets', this.props.outerWindowsillColor.image)} alt="" />
						</figure>
					) : null}
				</div>
			</div>
		);
	}
	
	handleClick = (event) => {
		if (this.props) {
			this.props.handleClick(this.props.outerWindowsillColor);
		}
	};
}

export interface AccessoryDetailProps {
	isSelected: boolean;
	accessory: Accessory;
	handleClick: (accessory: Accessory) => void;
}

export class AccessoryDetail extends React.Component<AccessoryDetailProps, {}> {
	render() {
		if (!this.props) {
			return null;
		}
		
		return (
			<div className={styles.accessoryDetail + (this.props.isSelected ? ` ${styles.isSelected}` : '')} onClick={this.handleClick}>
				<h4 className={styles.accessoryDetailHeading}>{this.props.accessory.name[languageStream.value.current]}</h4>
				
				<figure className={styles.accessoryDetailPhoto}>
					<img src={this.props.accessory.image} alt="" />
				</figure>
				
				<p className={styles.accessoryDetailPrice}>
					{i18n.translate`AccessoriesStep - accessory detail - price - ${getConvertedPrice(this.props.accessory.priceWithoutVat * VAT_MODIFIER[languageStream.value.current])}:c`}
				</p>
			</div>
		);
	}
	
	handleClick = (event) => {
		if (this.props) {
			this.props.handleClick(this.props.accessory);
		}
	};
}

export interface AccessoriesStepProps {
	isOpen: boolean;
}

export interface AccessoriesStepState {
	panel: string | null;
}

export default class AccessoriesStep extends React.Component<AccessoriesStepProps, AccessoriesStepState> {
	onLanguageStream: Stream<{}>;
	
	state = {
		panel: null,
	};
	
	refs: {
		root?: Element;
	} = {};
	
	componentDidMount() {
		this.onLanguageStream = languageStream.on(() => {
			// requestAnimationFrame(() => {
			this.forceUpdate();
			// });
		});
	}
	
	componentWillUnmount() {
		this.onLanguageStream.end.push(true);
	}
	
	render() {
		let {
			sunblindColors,
			innerWindowsillSizes,
			innerWindowsillColors,
			outerWindowsillSizes,
			outerWindowsillColors,
			accessories,
			accessoryCategories,
		} = dataStream.value;
		let order = orderStream.value;
		let orderItem;
		let innerWindowsillWidth = 0;
		let outerWindowsillWidth = 0;
		let innerWindowsillDepth = 0;
		let outerWindowsillDepth = 0;
		let sunblindColor;
		let innerWindowsillColor;
		let outerWindowsillColor;
		let isInnerWindowsillComplete = false;
		let isOuterWindowsillComplete = false;
		
		if (order.currentItem !== null) {
			orderItem = order.items[order.currentItem];
			
			if (orderItem && orderItem.innerWindowsill) {
				innerWindowsillWidth = orderItem.innerWindowsill.width;
				innerWindowsillDepth = orderItem.innerWindowsill.depth;
				
				isInnerWindowsillComplete = !!(
					orderItem.innerWindowsill &&
					orderItem.innerWindowsill.width &&
					orderItem.innerWindowsill.depth &&
					orderItem.innerWindowsill.color &&
					orderItem.innerWindowsill.price
				);
			}
			
			if (orderItem && orderItem.outerWindowsill) {
				outerWindowsillWidth = orderItem.outerWindowsill.width;
				outerWindowsillDepth = orderItem.outerWindowsill.depth;
				
				isOuterWindowsillComplete = !!(
					orderItem.outerWindowsill &&
					orderItem.outerWindowsill.width &&
					orderItem.outerWindowsill.depth &&
					orderItem.outerWindowsill.color &&
					orderItem.outerWindowsill.price
				);
			}
			
			if (orderItem && orderItem.sunblind) {
				sunblindColor = _.find(sunblindColors, orderItem.sunblind.color);
			}
			
			if (orderItem && orderItem.innerWindowsill) {
				innerWindowsillColor = _.find(innerWindowsillColors, orderItem.innerWindowsill.color);
			}
			
			if (orderItem && orderItem.outerWindowsill) {
				outerWindowsillColor = _.find(outerWindowsillColors, orderItem.outerWindowsill.color);
			}
		}
		
		let filteredInnerWindowsillSizes = innerWindowsillSizes.filter(
			(innerWindowsillSize) =>
				innerWindowsillDepth >= innerWindowsillSize.depths[0] && innerWindowsillDepth <= innerWindowsillSize.depths[1],
		);
		let depths = outerWindowsillSizes.map((outerWindowsillSize) => outerWindowsillSize.depth);
		let roundedDepth = getClosestNumber(depths, outerWindowsillDepth);
		let filteredOuterWindowsillSizes = outerWindowsillSizes.filter(
			(outerWindowsillSize) => roundedDepth === outerWindowsillSize.depth,
		);
		
		let baseUrl = 'okna-hned.cz';
		switch (languageStream.value.current) {
			case 'sk-SK':
				baseUrl = 'okna-hned.sk';
				break;
			case 'de-DE':
				baseUrl = 'fenster-jetzt.de';
				break;
			case 'de-AT':
				baseUrl = 'fenster-jetzt.at';
				break;
			case 'hu-HU':
				baseUrl = 'ablakok-most.hu';
				break;
		}
		
		let headingText = i18n.translate`AccessoriesStep - heading 1`;
		let warningText;
		let infoText;
		let panelElement = (
			<div>
				<div className={styles.panelSelection}>
					<div>
						<h3 className={styles.categoryHeading}>{i18n.translate`AccessoriesStep - category heading 1`}</h3>
						<ButtonGroup>
							<Button
								type="outlined"
								color="secondary"
								label={i18n.translate`AccessoriesStep - category button 1`}
								handleClick={this.handleShowSunblindPanel}
							/>
							{orderItem && orderItem.sunblind ? (
								<Button
									type="outlined"
									color="secondary"
									label={i18n.translate`AccessoriesStep - category button 2`}
									handleClick={this.handleSunblindDeleteClick}
								/>
							) : null}
						</ButtonGroup>
						
						{sunblindColor ? (
							<div className={styles.selectedAccessory}>
								<h4 className={styles.selectedAccessoryHeading}>{i18n.translate`AccessoriesStep - heading 6`}</h4>
								<p className={styles.selectedAccessoryDescription}>
									{orderItem.sunblind.color[languageStream.value.current]}
								</p>
								{orderItem.sunblind.price ? (
									<p className={styles.selectedAccessoryPrice}>
										{i18n.translate`AccessoriesStep - accessory - price - ${getConvertedPrice(orderItem.sunblind.price * VAT_MODIFIER[languageStream.value.current])}:c`}</p>
								) : null}
								<figure className={styles.selectedAccessoryPhoto}>
									{sunblindColor.image ? <img src={link('assets', sunblindColor.image)} alt="" /> : null}
								</figure>
							</div>
						) : null}
					</div>
					
					<div>
						<h3 className={styles.categoryHeading}>{i18n.translate`AccessoriesStep - category heading 2`}</h3>
						
						<ButtonGroup>
							<Button
								type="outlined"
								color="secondary"
								label={i18n.translate`AccessoriesStep - category button 3`}
								handleClick={this.handleShowInnerWindowsillPanel}
							/>
							<Button
								type="outlined"
								color="secondary"
								label={i18n.translate`AccessoriesStep - category button 4`}
								handleClick={this.handleShowOuterWindowsillPanel}
							/>
							{orderItem && orderItem.innerWindowsill ? (
								<Button
									type="outlined"
									color="secondary"
									label={i18n.translate`AccessoriesStep - category button 5`}
									handleClick={this.handleInnerWindowsillDeleteClick}
								/>
							) : null}
							{orderItem && orderItem.outerWindowsill ? (
								<Button
									type="outlined"
									color="secondary"
									label={i18n.translate`AccessoriesStep - category button 6`}
									handleClick={this.handleOuterWindowsillDeleteClick}
								/>
							) : null}
						</ButtonGroup>
						
						{innerWindowsillColor ? (
							<div className={styles.selectedAccessory}>
								<h4 className={styles.selectedAccessoryHeading}>{i18n.translate`AccessoriesStep - heading 7`}</h4>
								<p className={styles.selectedAccessoryDescription}>
									{orderItem.innerWindowsill.color?.[languageStream.value.current] ?? null}{' '}
									{orderItem.innerWindowsill.width} × {orderItem.innerWindowsill.depth} mm
								</p>
								{orderItem.innerWindowsill.price ? (
									<p className={styles.selectedAccessoryPrice}>
										{i18n.translate`AccessoriesStep - accessory - price - ${getConvertedPrice(((orderItem.innerWindowsill.price * orderItem.innerWindowsill.width) / MM_IN_M) * VAT_MODIFIER[languageStream.value.current])}:c`}
									</p>
								) : null}
								<figure className={styles.selectedAccessoryPhoto}>
									{innerWindowsillColor.image ? <img src={link('assets', innerWindowsillColor.image)} alt="" /> : null}
								</figure>
							</div>
						) : null}
						
						{outerWindowsillColor ? (
							<div className={styles.selectedAccessory}>
								<h4 className={styles.selectedAccessoryHeading}>{i18n.translate`AccessoriesStep - heading 8`}</h4>
								<p className={styles.selectedAccessoryDescription}>
									{orderItem.outerWindowsill.color?.[languageStream.value.current] ?? null}{' '}
									{orderItem.outerWindowsill.width} × {orderItem.outerWindowsill.depth} mm
								</p>
								{orderItem.outerWindowsill.price ? (
									<p className={styles.selectedAccessoryPrice}>
										{i18n.translate`AccessoriesStep - accessory - price - ${getConvertedPrice(((orderItem.outerWindowsill.price * orderItem.outerWindowsill.width) / MM_IN_M) *
											VAT_MODIFIER[languageStream.value.current],)}:c`}
									</p>
								) : null}
								<figure className={styles.selectedAccessoryPhoto}>
									{outerWindowsillColor.image ? <img src={link('assets', outerWindowsillColor.image)} alt="" /> : null}
								</figure>
							</div>
						) : null}
					</div>
					
					<div>
						<h3 className={styles.categoryHeading}>{i18n.translate`AccessoriesStep - category heading 3`}</h3>
						
						<p dangerouslySetInnerHTML={{__html: i18n.translate`AccessoriesStep - info 2`}} />
						
						{/*<ButtonGroup>
            <Button type="outlined" color="secondary" label={i18n.translate `AccessoriesStep - category button 7`} handleClick={this.handleOthersPanel} />
          </ButtonGroup>

          {orderItem && orderItem.accessories && orderItem.accessories.length ? orderItem.accessories.map((accessory, index) => {
            let image;
            let originalAccesory = _.find(accessories, {id: accessory.id});

            if (originalAccesory) {
              image = originalAccesory.image;
            }

            return <div key={index} className={styles.selectedAccessory}>
              <h4 className={styles.selectedAccessoryHeading}>{i18n.translate `AccessoriesStep - heading 9`}</h4>
              <p className={styles.selectedAccessoryDescription}>{accessory.name[languageStream.value.current]}</p>
              {accessory.price ? <p className={styles.selectedAccessoryPrice}>{i18n.translate `AccessoriesStep - accessory - price - ${getConvertedPrice(accessory.price * VAT_MODIFIER[languageStream.value.current])}:c`}</p> : null}
              <figure className={styles.selectedAccessoryPhoto}>{image ? <img src={image} alt="" /> : null}</figure>
            </div>;
          }) : null}*/}
					</div>
				</div>
				
				<ButtonGroup align="center">
					<Button
						type="flat"
						size="large"
						label={i18n.translate`AccessoriesStep - button 1`}
						handleClick={this.handleCloseClick}
					/>
				</ButtonGroup>
			</div>
		);
		
		if (this.state.panel === 'sunblind') {
			headingText = i18n.translate`AccessoriesStep - heading 2`;
			panelElement = (
				<div>
					<ul className={styles.sunblindColorsList}>
						{sunblindColors.map((sunblindColor, index) => (
							<li key={index}>
								<SunblindColorDetail
									isSelected={
										orderItem &&
										orderItem.sunblind &&
										orderItem.sunblind.color[languageStream.value.current] ===
										sunblindColor.name[languageStream.value.current]
									}
									sunblindColor={sunblindColor}
									handleClick={this.handleSunblindColorClick}
								/>
							</li>
						))}
					</ul>
					
					<div className={styles.needHelpLink}>
						<a href={`https://www.${baseUrl}/content/58-barvy-zaluzii`} target="_blank">
							{i18n.translate`ColorStep - color swatch`}
						</a>
					</div>
					
					<ButtonGroup align="center">
						<Button
							type="flat"
							size="large"
							label={i18n.translate`AccessoriesStep - button 2`}
							handleClick={this.handleHidePanels}
						/>
					</ButtonGroup>
				</div>
			);
		} else if (this.state.panel === 'innerWindowsill') {
			headingText = i18n.translate`AccessoriesStep - heading 3`;
			panelElement = (
				<div>
					<div className={styles.subpanels}>
						<div className={styles.subpanel}>
							<h3 className={styles.subpanelHeading}>{i18n.translate`AccessoriesStep - select dimensions`}</h3>
							
							<div className={styles.subpanelInput}>
								<label htmlFor="innerWindowsillWidth">{i18n.translate`AccessoriesStep - dimension - width`}</label>
								<Input
									type="text"
									name="innerWindowsillWidth"
									unit="mm"
									isUncontrolled={true}
									handleChange={this.handleInnerWindowsillWidthChange}
								/>
							</div>
							
							<div className={styles.subpanelInput}>
								<label htmlFor="innerWindowsillDepth">{i18n.translate`AccessoriesStep - dimension - depth`}</label>
								<Input
									type="text"
									name="innerWindowsillDepth"
									unit="mm"
									isUncontrolled={true}
									handleChange={this.handleInnerWindowsillDepthChange}
								/>
							</div>
						</div>
						
						<div className={styles.subpanel}>
							<h3 className={styles.subpanelHeading}>{i18n.translate`AccessoriesStep - select color`}</h3>
							
							<ul className={styles.innerWindowsillColorsList}>
								{innerWindowsillColors.map((innerWindowsillColor, index) => (
									<li key={index}>
										<InnerWindowsillColorDetail
											isSelected={
												orderItem &&
												orderItem.innerWindowsill &&
												orderItem.innerWindowsill.color?.[languageStream.value.current] ===
												innerWindowsillColor.name[languageStream.value.current]
											}
											innerWindowsillColor={innerWindowsillColor}
											handleClick={this.handleInnerWindowsillColorClick}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					
					<ButtonGroup align="center">
						<Button
							type="flat"
							size="large"
							label={i18n.translate`AccessoriesStep - button 3`}
							isDisabled={!isInnerWindowsillComplete}
							handleClick={this.handleSaveInnerWindowsillClick}
						/>
					</ButtonGroup>
				</div>
			);
		} else if (this.state.panel === 'outerWindowsill') {
			headingText = i18n.translate`AccessoriesStep - heading 4`;
			panelElement = (
				<div>
					<div className={styles.subpanels}>
						<div className={styles.subpanel}>
							<h3 className={styles.subpanelHeading}>{i18n.translate`AccessoriesStep - select dimensions`}</h3>
							
							<div className={styles.subpanelInput}>
								<label htmlFor="outerWindowsillWidth">{i18n.translate`AccessoriesStep - dimension - width`}</label>
								<Input
									type="text"
									name="outerWindowsillWidth"
									isUncontrolled={true}
									unit="mm"
									handleChange={this.handleOuterWindowsillWidthChange}
								/>
							</div>
							
							<div className={styles.subpanelInput}>
								<label htmlFor="outerWindowsillDepth">{i18n.translate`AccessoriesStep - dimension - depth`}</label>
								<Select
									type="text"
									name="outerWindowsillDepth"
									isUncontrolled={true}
									unit="mm"
									values={outerWindowsillSizes.map((outerWindowsillSize) => outerWindowsillSize.depth)}
									handleChange={this.handleOuterWindowsillDepthChange}
								/>
							</div>
						</div>
						
						<div className={styles.subpanel}>
							<h3 className={styles.subpanelHeading}>{i18n.translate`AccessoriesStep - select color`}</h3>
							
							<ul className={styles.outerWindowsillColorsList}>
								{outerWindowsillColors.map((outerWindowsillColor, index) => (
									<li key={index}>
										<OuterWindowsillColorDetail
											isSelected={
												orderItem &&
												orderItem.outerWindowsill &&
												orderItem.outerWindowsill.color?.[languageStream.value.current] ===
												outerWindowsillColor.name[languageStream.value.current]
											}
											outerWindowsillColor={outerWindowsillColor}
											handleClick={this.handleOuterWindowsillColorClick}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					
					<ButtonGroup align="center">
						<Button
							type="flat"
							size="large"
							label={i18n.translate`AccessoriesStep - button 4`}
							isDisabled={!isOuterWindowsillComplete}
							handleClick={this.handleSaveOuterWindowsillClick}
						/>
					</ButtonGroup>
				</div>
			);
		} else if (this.state.panel === 'others') {
			headingText = i18n.translate`AccessoriesStep - heading 5`;
			warningText = i18n.translate`AccessoriesStep - warning`;
			infoText = i18n.translate`AccessoriesStep - info 1`;
			panelElement = (
				<div>
					{accessoryCategories.map((accessoryCategory, index, accessoryCategories) => (
						<div key={index}
							 className={
								 styles.accessoryCategory + (index === accessoryCategories.length - 1 ? ` ${styles.isLast}` : '')
							 }
						>
							<h2 className={styles.categoryHeading}>{accessoryCategory.name[languageStream.value.current]}</h2>
							
							<div className={styles.accessoriesListWrapper}>
								<p className={styles.accessoriesListScrollLeft}>
									<Button
										type="invisible"
										size="large"
										icon="left-arrow"
										handleClick={this.handleLeftScrollClick.bind(this, index)}
									/>
								</p>
								<p className={styles.accessoriesListScrollRight}>
									<Button
										type="invisible"
										size="large"
										icon="right-arrow"
										handleClick={this.handleRightScrollClick.bind(this, index)}
									/>
								</p>
								<ul className={styles.accessoriesList}>
									{accessories.map((accessory, index) => {
										if (accessory.categoryId !== accessoryCategory.id) {
											return null;
										}
										
										let order = orderStream.value;
										let isSelected = false;
										
										if (order.currentItem !== null && order.items[order.currentItem].accessories) {
											isSelected = !!_.find(order.items[order.currentItem].accessories, {id: accessory.id});
										}
										
										return (
											<li key={index}>
												<AccessoryDetail
													accessory={accessory}
													isSelected={isSelected}
													handleClick={this.handleAccessoryClick}
												/>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					))}
					
					<ButtonGroup align="center">
						<Button
							type="flat"
							size="large"
							label={i18n.translate`AccessoriesStep - button 5`}
							handleClick={this.handleHidePanels}
						/>
					</ButtonGroup>
				</div>
			);
		}
		
		return (
			<section
				ref={(node) => {
					/*this.refs.root = node;*/
				}}
				className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}
			>
				<div className={styles.wrapper}>
					<h2 className={styles.heading}>{headingText}</h2>
					{warningText ? <p className={styles.warning}>{warningText}</p> : null}
					{infoText ? <p className={styles.warning}>{infoText}</p> : null}
					<span className={styles.closeButton}>
						<Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick} />
					</span>
					
					{panelElement}
				</div>
			</section>
		);
	}
	
	handleLeftScrollClick(index) {
		if (this.refs.root) {
			$(this.refs.root)
			.find(`.${styles.accessoriesList}`)
			.eq(index)[0].scrollLeft -= 100;
		}
	}
	
	handleRightScrollClick(index) {
		if (this.refs.root) {
			$(this.refs.root)
			.find(`.${styles.accessoriesList}`)
			.eq(index)[0].scrollLeft += 100;
		}
	}
	
	settleIncompletes() {
		let order = orderStream.value;
		let innerWindowsill;
		let outerWindowsill;
		
		if (order.currentItem !== null) {
			innerWindowsill = order.items[order.currentItem].innerWindowsill;
			outerWindowsill = order.items[order.currentItem].outerWindowsill;
		}
		
		if (!(innerWindowsill && innerWindowsill.width && innerWindowsill.depth && innerWindowsill.color && innerWindowsill.price)) {
			selectAccessoriesActionStream.push({
				innerWindowsill: null,
			});
		}
		
		if (!(outerWindowsill && outerWindowsill.width && outerWindowsill.depth && outerWindowsill.color && outerWindowsill.price)) {
			selectAccessoriesActionStream.push({
				outerWindowsill: null,
			});
		}
	}
	
	handleCloseClick = () => {
		if (this.state.panel === null) {
			this.settleIncompletes();
			openStepActionStream.push(null);
		} else {
			this.settleIncompletes();
			this.setState({panel: null});
		}
	};
	
	handleHidePanels = () => {
		this.setState({panel: null});
	};
	
	handleShowSunblindPanel = () => {
		this.setState({panel: 'sunblind'});
	};
	
	handleShowInnerWindowsillPanel = () => {
		this.setState({panel: 'innerWindowsill'});
	};
	
	handleShowOuterWindowsillPanel = () => {
		this.setState({panel: 'outerWindowsill'});
	};
	
	handleOthersPanel = () => {
		this.setState({panel: 'others'});
	};
	
	handleSunblindDeleteClick = () => {
		selectAccessoriesActionStream.push({
			sunblind: null,
		});
	};
	
	handleSunblindColorClick = (sunblindColor) => {
		let order = orderStream.value;
		let window;
		
		if (order.currentItem !== null) {
			window = order.items[order.currentItem].window;
		}
		
		if (!window) {
			return;
		}
		
		let width = window.width;
		let height = window.height;
		
		let {sunblindWings} = dataStream.value;
		let sunblindWing;
		for (let sw of sunblindWings) {
			for (let type of sw.types) {
				if (type === window.type) {
					sunblindWing = sw;
					break;
				}
			}
		}
		
		if (sunblindWing) {
			let filteredSunblindSizes = sunblindWing.sizes.filter(
				(sunblindSize) =>
					width >= sunblindSize.widths[0] &&
					width <= sunblindSize.widths[1] &&
					height >= sunblindSize.heights[0] &&
					height <= sunblindSize.heights[1]
			);
			
			if (filteredSunblindSizes[0]) {
				selectAccessoriesActionStream.push({
					sunblind: {
						color: sunblindColor.name,
						price: filteredSunblindSizes[0].price * SUNBLIND_MARGIN,
					},
				});
			}
		}
	};
	
	handleInnerWindowsillDeleteClick = () => {
		selectAccessoriesActionStream.push({
			innerWindowsill: null,
		});
	};
	
	handleInnerWindowsillWidthChange = (value) => {
		let innerWindowsillWidth = parseInt(value, 10);
		
		if (!_.isFinite(innerWindowsillWidth)) {
			return;
		}
		
		selectAccessoriesActionStream.push({
			innerWindowsill: {
				width: innerWindowsillWidth,
			},
		});
	};
	
	handleInnerWindowsillDepthChange = (value) => {
		let innerWindowsillDepth = parseInt(value, 10);
		
		if (!_.isFinite(innerWindowsillDepth)) {
			return;
		}
		
		let {innerWindowsillSizes} = dataStream.value;
		let filteredInnerWindowsillSizes = innerWindowsillSizes.filter(
			(innerWindowsillSize) =>
				innerWindowsillDepth >= innerWindowsillSize.depths[0] && innerWindowsillDepth <= innerWindowsillSize.depths[1],
		);
		
		if (filteredInnerWindowsillSizes[0]) {
			selectAccessoriesActionStream.push({
				innerWindowsill: {
					depth: innerWindowsillDepth,
					prices: filteredInnerWindowsillSizes[0].prices
				},
			});
		}
	};
	
	handleInnerWindowsillColorClick = (innerWindowsillColor) => {
		selectAccessoriesActionStream.push({
			innerWindowsill: {
				color: innerWindowsillColor.name,
				priceGroup: innerWindowsillColor.group
			},
		});
	};
	
	handleSaveInnerWindowsillClick = () => {
		this.settleIncompletes();
		this.setState({panel: null});
	};
	
	handleOuterWindowsillDeleteClick = () => {
		selectAccessoriesActionStream.push({
			outerWindowsill: null,
		});
	};
	
	handleOuterWindowsillWidthChange = (value) => {
		let outerWindowsillWidth = parseInt(value, 10);
		
		if (!_.isFinite(outerWindowsillWidth)) {
			return;
		}
		
		selectAccessoriesActionStream.push({
			outerWindowsill: {
				width: outerWindowsillWidth,
			},
		});
	};
	
	handleOuterWindowsillDepthChange = (value) => {
		let outerWindowsillDepth = parseInt(value, 10);
		
		if (!_.isFinite(outerWindowsillDepth)) {
			return;
		}
		
		let {outerWindowsillSizes} = dataStream.value;
		let depths = outerWindowsillSizes.map((outerWindowsillSize) => outerWindowsillSize.depth);
		let roundedDepth = getClosestNumber(depths, outerWindowsillDepth);
		let filteredOuterWindowsillSizes = outerWindowsillSizes.filter(
			(outerWindowsillSize) => roundedDepth === outerWindowsillSize.depth,
		);
		
		if (filteredOuterWindowsillSizes[0]) {
			selectAccessoriesActionStream.push({
				outerWindowsill: {
					depth: roundedDepth,
					prices: filteredOuterWindowsillSizes[0].prices,
				},
			});
		}
	};
	
	handleOuterWindowsillColorClick = (outerWindowsillColor) => {
		selectAccessoriesActionStream.push({
			outerWindowsill: {
				color: outerWindowsillColor.name,
				priceGroup: outerWindowsillColor.priceGroup,
			},
		});
	};
	
	handleSaveOuterWindowsillClick = () => {
		this.settleIncompletes();
		this.setState({panel: null});
	};
	
	handleAccessoryClick = (accessory) => {
		selectAccessoriesActionStream.push({
			accessory: {
				id: accessory.id,
				name: accessory.name,
				price: parseFloat(accessory.priceWithoutVat),
				count: 1,
				categoryId: accessory.categoryId,
			},
		});
	};
}
