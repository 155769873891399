import _ from 'lodash';
import {Color} from '../types/data';

export default function findProfileWithinColor(color: Color, profileId: Number): Boolean {
	// find profile within color's price modifiers
	let found = false;

	color.priceModifiers.forEach((priceModifier) => {
		let foundProfileId = _.find(priceModifier.profileIds, (value) => value === profileId);

		if (foundProfileId) {
			found = true;
		}
	});

	return found;
}
