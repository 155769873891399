import Stream from '../libs/Stream';


export interface SelectProfileAction {
	id: number;
	name: string;
	priceModifier: number;
	rebate: number;
}

let selectProfileActionStream: Stream<SelectProfileAction> = new Stream() as Stream<SelectProfileAction>;

export default selectProfileActionStream;
