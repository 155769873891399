import React from 'react';


const STROKE_WIDTH = 2;
const WIDTH = 14;
const HEIGHT = 60;

export let Hinge = ({left, top, color, type}) => {
	let x = left;
	let y = top;

	if (type === 'A_LEFT') {
		x -= WIDTH - STROKE_WIDTH / 2;
		y -= 0;

		return <g>
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT - STROKE_WIDTH} />
			<line
				x1={x + STROKE_WIDTH / 2}
				y1={y + STROKE_WIDTH / 2 + HEIGHT * 0.333}
				x2={x + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				y2={y + STROKE_WIDTH / 2 + HEIGHT * 0.333}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
			<line
				x1={x + STROKE_WIDTH / 2}
				y1={y + STROKE_WIDTH / 2 + HEIGHT * 0.75}
				x2={x + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				y2={y + STROKE_WIDTH / 2 + HEIGHT * 0.75}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
		</g>;
	} else if (type === 'A_RIGHT') {
		x -= STROKE_WIDTH / 2;
		y -= 0;

		return <g>
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT - STROKE_WIDTH} />
			<line
				x1={x + STROKE_WIDTH / 2}
				y1={y + STROKE_WIDTH / 2 + HEIGHT * 0.333}
				x2={x + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				y2={y + STROKE_WIDTH / 2 + HEIGHT * 0.333}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
			<line
				x1={x + STROKE_WIDTH / 2}
				y1={y + STROKE_WIDTH / 2 + HEIGHT * 0.75}
				x2={x + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				y2={y + STROKE_WIDTH / 2 + HEIGHT * 0.75}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
		</g>;
	} else if (type === 'B_LEFT') {
		x -= WIDTH - STROKE_WIDTH / 2;
		y -= HEIGHT;

		return <g>
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT - STROKE_WIDTH} />
			<line
				x1={x + STROKE_WIDTH / 2}
				y1={y + STROKE_WIDTH / 2 + HEIGHT * 0.8}
				x2={x + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				y2={y + STROKE_WIDTH / 2 + HEIGHT * 0.8}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
		</g>;
	} else if (type === 'B_RIGHT') {
		x -= STROKE_WIDTH / 2;
		y -= HEIGHT;

		return <g>
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT - STROKE_WIDTH} />
			<line
				x1={x + STROKE_WIDTH / 2}
				y1={y + STROKE_WIDTH / 2 + HEIGHT * 0.8}
				x2={x + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				y2={y + STROKE_WIDTH / 2 + HEIGHT * 0.8}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
		</g>;
	} else if (type === 'C_LEFT') {
		x += 55;
		y -= STROKE_WIDTH / 2;

		return <g>
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={HEIGHT - STROKE_WIDTH} height={WIDTH - STROKE_WIDTH} />
			<line
				x1={x + STROKE_WIDTH / 2 + HEIGHT * 0.333}
				y1={y + STROKE_WIDTH / 2}
				x2={x + STROKE_WIDTH / 2 + HEIGHT * 0.333}
				y2={y + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
			<line
				x1={x + STROKE_WIDTH / 2 + HEIGHT * 0.666}
				y1={y + STROKE_WIDTH / 2}
				x2={x + STROKE_WIDTH / 2 + HEIGHT * 0.666}
				y2={y + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
		</g>;
	} else if (type === 'C_RIGHT') {
		x -= HEIGHT + 55;
		y -= STROKE_WIDTH / 2;

		return <g>
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={HEIGHT - STROKE_WIDTH} height={WIDTH - STROKE_WIDTH} />
			<line
				x1={x + STROKE_WIDTH / 2 + HEIGHT * 0.333}
				y1={y + STROKE_WIDTH / 2}
				x2={x + STROKE_WIDTH / 2 + HEIGHT * 0.333}
				y2={y + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
			<line
				x1={x + STROKE_WIDTH / 2 + HEIGHT * 0.666}
				y1={y + STROKE_WIDTH / 2}
				x2={x + STROKE_WIDTH / 2 + HEIGHT * 0.666}
				y2={y + STROKE_WIDTH / 2 + WIDTH - STROKE_WIDTH}
				stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10"
			/>
		</g>;
	} else if (type === 'D_LEFT_TOP') {
		x -= WIDTH - STROKE_WIDTH / 2;
		y += 80;

		return <g>
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH * 2 - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH / 2} />
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2 + HEIGHT / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH} />
		</g>;
	} else if (type === 'D_RIGHT_TOP') {
		x -= STROKE_WIDTH / 2;
		y += 80;

		return <g>
			<rect x={x + STROKE_WIDTH / 2 - WIDTH} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH * 2 - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH / 2} />
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2 + HEIGHT / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH} />
		</g>;
	} else if (type === 'D_LEFT_MIDDLE') {
		x -= WIDTH - STROKE_WIDTH / 2;
		y -= HEIGHT / 2;

		return <g>
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH * 2 - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH / 2} />
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2 + HEIGHT / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH} />
		</g>;
	} else if (type === 'D_RIGHT_MIDDLE') {
		x -= STROKE_WIDTH / 2;
		y -= HEIGHT / 2;

		return <g>
			<rect x={x + STROKE_WIDTH / 2 - WIDTH} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH * 2 - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH / 2} />
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2 + HEIGHT / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH} />
		</g>;
	} else if (type === 'D_LEFT_BOTTOM') {
		x -= WIDTH - STROKE_WIDTH / 2;
		y -= HEIGHT + 80;

		return <g>
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH * 2 - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH / 2} />
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2 + HEIGHT / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH} />
		</g>;
	} else if (type === 'D_RIGHT_BOTTOM') {
		x -= STROKE_WIDTH / 2;
		y -= HEIGHT + 80;

		return <g>
			<rect x={x + STROKE_WIDTH / 2 - WIDTH} y={y + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH * 2 - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH / 2} />
			<rect x={x + STROKE_WIDTH / 2} y={y + STROKE_WIDTH / 2 + HEIGHT / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={WIDTH - STROKE_WIDTH} height={HEIGHT / 2 - STROKE_WIDTH} />
		</g>;
	}

	return null;
};
