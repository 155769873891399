import {Color, Colors} from '../types/data';

export default function findDefaultColor(colors: Colors, materialId ?: number): any {
	let results = colors.filter((color) => color.materialIds.includes(materialId) && color.default === true);
	
	return {
		id: results[0].id,
		name: results[0].name,
		category: results[0].category,
		sidesCount: 2,
		priceModifiers: results[0].priceModifiers
	};
}
