import config from '../config';
import constants from '../internals/constants';
import languageStream from '../streams/languageStream';

export default function getConvertedPrice(value) {
	if (languageStream.value && (languageStream.value.current === constants.SK_SK || languageStream.value.current === constants.DE_DE || languageStream.value.current === constants.DE_AT)) {
		// console.log([value, config.ONE_CZK_IN_EUR, value * config.ONE_CZK_IN_EUR]);
		return value * config.ONE_CZK_IN_EUR;
	}
	
	if (languageStream.value && languageStream.value.current === constants.HU_HU) {
		return value * config.ONE_CZK_IN_HUF;
	}
	
	return Math.round(value);
}
