import $ from 'jquery';
import _ from 'lodash';

import Stream from '../libs/Stream';
import data from '../data';
import config from '../config';
import {WindowTypes, WindowPrices, WindowSizes, WindowRecommendations, Materials, Profiles, Glasses, Colors, ColorCategories, SunblindWings, SunblindColors, InnerWindowsillSizes, InnerWindowsillColors, OuterWindowsillSizes, OuterWindowsillColors, Accessories, AccessoryCategories} from '../types/data';
import languageStream from "./languageStream";
import constants from "../internals/constants";


const ONE_CZK_IN_EUR = config.ONE_CZK_IN_EUR;

export interface DataStreamValue {
	windowTypes: WindowTypes;
	windowPrices: WindowPrices;
	windowSizes: WindowSizes;
	windowRecommendations: WindowRecommendations;
	materials: Materials;
	profiles: Profiles;
	glasses: Glasses;
	colors: Colors;
	colorCategories: ColorCategories;
	sunblindWings: SunblindWings;
	sunblindColors: SunblindColors;
	innerWindowsillSizes: InnerWindowsillSizes;
	innerWindowsillColors: InnerWindowsillColors;
	outerWindowsillSizes: OuterWindowsillSizes;
	outerWindowsillColors: OuterWindowsillColors;
	accessories: Accessories;
	accessoryCategories: AccessoryCategories;
	// ONE_CZK_IN_EUR: number;
}

let dataStream: Stream<DataStreamValue> = new Stream({
	windowTypes: data.windowTypes,
	windowPrices: data.windowPrices,
	windowSizes: [],
	windowRecommendations: data.windowRecommendations,
	materials: data.materials,
	profiles: data.profiles,
	glasses: data.glasses,
	colors: data.colors,
	colorCategories: data.colorCategories,
	sunblindWings: data.sunblindWings,
	sunblindColors: data.sunblindColors,
	innerWindowsillSizes: data.innerWindowsillSizes,
	innerWindowsillColors: data.innerWindowsillColors,
	outerWindowsillSizes: data.outerWindowsillSizes,
	outerWindowsillColors: data.outerWindowsillColors,
	accessories: [],
	accessoryCategories: [],
	// ONE_CZK_IN_EUR
}) as Stream<DataStreamValue>;

dataStream.push((async () => {
	let response;
	
	let subdomain = 'www';
	if (window.location.hostname === 'stage.kalkulator-oken.cz') {
		subdomain = 'test:test@test';
	}

	try {
		let productListPath = `https://${subdomain}.okna-hned.cz/scripts/export-products-list/products-list-configurator-json-multi.json`;
		if (process.env.NODE_ENV !== 'production') {
			productListPath = '/scripts/ProductsListConfiguratorJsonMulti.json';
		}
		
		response = await Promise.resolve($.getJSON(productListPath));
	} catch (error) {
		console.error(error);

		return dataStream.value;
	}

	let accessories = response.products;
	let accessoryCategories = accessories.map((accessory) => ({id: accessory.categoryId, name: accessory.categoryName}));

	accessoryCategories = _.uniqWith(accessoryCategories, _.isEqual);

	try {
		response = await Promise.resolve($.getJSON(`https://${subdomain}.okna-hned.cz/scripts/getExRate.php`));
	} catch (error) {
		console.error(error);
	}
	config.ONE_CZK_IN_EUR = parseFloat(response.data[0].conversion);
	
	try {
		response = await Promise.resolve($.getJSON(`https://${subdomain}.okna-hned.cz/scripts/getExRateFt.php`));
	} catch (error) {
		console.error(error);
	}
	config.ONE_CZK_IN_HUF = parseFloat(response.data[0].conversion);

	// console.log(parseFloat(response.data[0].conversion));

	return {
		windowTypes: data.windowTypes,
		windowPrices: data.windowPrices,
		windowSizes: [],
		windowRecommendations: data.windowRecommendations,
		materials: data.materials,
		profiles: data.profiles,
		glasses: data.glasses,
		colors: data.colors,
		colorCategories: data.colorCategories,
		sunblindWings: data.sunblindWings,
		sunblindColors: data.sunblindColors,
		innerWindowsillSizes: data.innerWindowsillSizes,
		innerWindowsillColors: data.innerWindowsillColors,
		outerWindowsillSizes: data.outerWindowsillSizes,
		outerWindowsillColors: data.outerWindowsillColors,
		accessories,
		accessoryCategories,
		// ONE_CZK_IN_EUR: parseFloat(response.data[0].conversion)
	};
})());

(global as any).dataStream = dataStream;

export default dataStream;
