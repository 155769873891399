/* eslint-disable no-self-compare, no-extend-native */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import bluebird from 'bluebird';

import support from './support';
import constants from './constants';


const CLIENT_PLATFORM = constants.CLIENT_PLATFORM;

global.Promise = bluebird;

if (process.env.NODE_ENV === 'development' && support.platform === CLIENT_PLATFORM) {
	// require('inferno-devtools');
}

function includes(searchElement, fromIndex = 0) {
	if (this === null) {
		throw new TypeError('Array.prototype.includes called on null or undefined');
	}

	let O = Object(this);
	let len = parseInt(O.length, 10) || 0;

	if (len === 0) {
		return false;
	}

	let n = parseInt(fromIndex as any, 10);
	let k;

	if (n >= 0) {
		k = n;
	} else {
		k = len + n;

		if (k < 0) {
			k = 0;
		}
	}

	let currentElement;

	while (k < len) {
		currentElement = O[k];

		if (searchElement === currentElement || (searchElement !== searchElement && currentElement !== currentElement)) { // NaN !== NaN
			return true;
		}

		k++;
	}
	return false;
}

if (!Array.prototype.includes) {
	Array.prototype.includes = includes;
}

function areIntlLocalesSupported(...locales: Array<string>) {
	if (typeof Intl === 'undefined') {
		return false;
	}

	let intlConstructors = [
		Intl.Collator,
		Intl.DateTimeFormat,
		Intl.NumberFormat
	].filter((intlConstructor) => intlConstructor);

	if (intlConstructors.length === 0) {
		return false;
	}

	return intlConstructors.every((intlConstructor) => {
		let supportedLocales = (intlConstructor as any).supportedLocalesOf(locales);

		return supportedLocales.length === locales.length;
	});
}

if (global.Intl) {
	// Determine if the built-in `Intl` has the locale data we need.
	if (!areIntlLocalesSupported('cs-CZ')) {
		// `Intl` exists, but it doesn't have the data we need, so load the
		// polyfill and patch the constructors we need with the polyfill's.
		let IntlPolyfill = require('intl');

		Intl.NumberFormat = IntlPolyfill.NumberFormat;
		Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
	}
} else {
	// No `Intl`, so use and load the polyfill.
	global.Intl = require('intl');

	require('intl/locale-data/jsonp/cs.js');
	require('intl/locale-data/jsonp/sk.js');
}

if (global.location && !global.location.origin) { // Some browsers (mainly IE) does not have this property, so we need to build it manually...
	global.location.origin = global.location.protocol + '//' + global.location.hostname + (global.location.port ? (':' + global.location.port) : '');
}
