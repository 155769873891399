// import Inferno from 'inferno';
import React from 'react';

import styles from './Button.css';


export default function ButtonGroup(props) {
	let alignClass;

	if (props && props.align === 'center') {
		alignClass = styles.hasCenterAlign;
	}

	return <div className={styles.buttonGroup + (alignClass ? ` ${alignClass}` : '')}>{props.children}</div>;
}
