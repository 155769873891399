import React, {useRef, useEffect} from 'react';


const STROKE_WIDTH = 2;

export let Frame = ({left, top, width, height, color, onResize}) => {
	let rectElement = useRef(null);

	useEffect(() => {
		function handleResize() {
			if (onResize) {
				onResize(rectElement.current.getBoundingClientRect());
			}
		}

		handleResize();
		setTimeout(handleResize, 50);
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [onResize, width, height]);

	return <rect ref={rectElement} x={left + STROKE_WIDTH / 2} y={top + STROKE_WIDTH / 2} fill={color} stroke="#231F20" strokeWidth={STROKE_WIDTH} strokeMiterlimit="10" width={width - STROKE_WIDTH} height={height - STROKE_WIDTH} />;
};
