/**
 * Optimized internal one-arity curry function.
 *
 * @private
 * @category Function
 * @param {Function} fn The function to curry.
 * @return {Function} The curried function.
 */
export function curry1(fn) {
	return function fn1(a) {
		if (arguments.length === 0) {
			return fn1;
		}

		return fn(a);
	};
}
