/* eslint-disable complexity */

import React from 'react';

const STROKE_WIDTH = 2;
const LINE_SPACE = 20;
const LINE_GAP = 15;

export let Glass = ({left, top, width, height, color, frame, lines, view}) => {
	const FRAME_GLASS_SPACE = view === 'OUTER' ? 5 * 8 : 7 * 8;

	let glassLeft = left + STROKE_WIDTH / 2 + FRAME_GLASS_SPACE;
	let glassTop = top + STROKE_WIDTH / 2 + FRAME_GLASS_SPACE;
	let glassWidth = width - STROKE_WIDTH - FRAME_GLASS_SPACE * 2;
	let glassHeight = height - STROKE_WIDTH - FRAME_GLASS_SPACE * 2;

	if (!frame) {
		glassLeft = left + STROKE_WIDTH / 2;
		glassTop = top + STROKE_WIDTH / 2;
		glassWidth = width - STROKE_WIDTH;
		glassHeight = height - STROKE_WIDTH;

		return (
			<g>
				{view === 'OUTER' ? 
					<linearGradient
						id="glass-gradient-outer"
						gradientUnits="objectBoundingBox"
						x1="25%"
						y1="0%"
						x2="100%"
						y2="100%"
					>
						<stop offset="0" stopColor="#ABE0F9" />
						<stop offset="0.4086" stopColor="#FFFFFF" />
						<stop offset="0.7446" stopColor="#ABE0F9" />
						<stop offset="1" stopColor="#66CDF5" />
					</linearGradient> : 
					<linearGradient
						id="glass-gradient-inner"
						gradientUnits="objectBoundingBox"
						x1="100%"
						y1="0%"
						x2="25%"
						y2="100%"
					>
						<stop offset="0" stopColor="#ABE0F9" />
						<stop offset="0.4086" stopColor="#FFFFFF" />
						<stop offset="0.7446" stopColor="#ABE0F9" />
						<stop offset="1" stopColor="#66CDF5" />
					</linearGradient>
				}
				<rect
					x={glassLeft}
					y={glassTop}
					width={glassWidth}
					height={glassHeight}
					fill={color ? color : `url(#${view === 'OUTER' ? 'glass-gradient-outer' : 'glass-gradient-inner'})`}
				/>

				{lines && lines.includes('TO_RIGHT') ? 
					<>
						<line
							x1={glassLeft + STROKE_WIDTH / 2}
							y1={glassTop + STROKE_WIDTH / 2}
							x2={glassLeft + glassWidth - STROKE_WIDTH / 2}
							y2={glassTop + 0.5 * glassHeight}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
						<line
							x1={glassLeft + glassWidth - STROKE_WIDTH / 2}
							y1={glassTop + 0.5 * glassHeight}
							x2={glassLeft + STROKE_WIDTH / 2}
							y2={glassTop + glassHeight - STROKE_WIDTH / 2}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
					</> :
					null}

				{lines && lines.includes('TO_LEFT') ? 
					<>
						<line
							x1={glassLeft + glassWidth - STROKE_WIDTH / 2}
							y1={glassTop + STROKE_WIDTH / 2}
							x2={glassLeft + STROKE_WIDTH / 2}
							y2={glassTop + 0.5 * glassHeight}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
						<line
							x1={glassLeft + STROKE_WIDTH / 2}
							y1={glassTop + 0.5 * glassHeight}
							x2={glassLeft + glassWidth - STROKE_WIDTH / 2}
							y2={glassTop + glassHeight - STROKE_WIDTH / 2}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
					</> :
					null}

				{lines && lines.includes('TO_TOP') ? 
					<>
						<line
							x1={glassLeft + STROKE_WIDTH / 2}
							y1={glassTop + glassHeight - STROKE_WIDTH / 2}
							x2={glassLeft + 0.5 * glassWidth}
							y2={glassTop + STROKE_WIDTH / 2}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
						<line
							x1={glassLeft + 0.5 * glassWidth}
							y1={glassTop + STROKE_WIDTH / 2}
							x2={glassLeft + glassWidth - STROKE_WIDTH / 2}
							y2={glassTop + glassHeight - STROKE_WIDTH / 2}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
					</> :
					null}

				{lines && lines.includes('LEFT') ? 
					<>
						<line
							x1={glassLeft + STROKE_WIDTH / 2 + LINE_SPACE}
							y1={glassTop + STROKE_WIDTH / 2}
							x2={glassLeft + STROKE_WIDTH / 2 + LINE_SPACE}
							y2={glassTop + glassHeight - STROKE_WIDTH / 2}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
						<line
							x1={glassLeft + STROKE_WIDTH / 2 + LINE_SPACE + LINE_GAP}
							y1={glassTop + STROKE_WIDTH / 2}
							x2={glassLeft + STROKE_WIDTH / 2 + LINE_SPACE + LINE_GAP}
							y2={glassTop + glassHeight - STROKE_WIDTH / 2}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
					</> :
					null}

				{lines && lines.includes('RIGHT') ? 
					<>
						<line
							x1={glassLeft + glassWidth - STROKE_WIDTH / 2 - LINE_SPACE}
							y1={glassTop + STROKE_WIDTH / 2}
							x2={glassLeft + glassWidth - STROKE_WIDTH / 2 - LINE_SPACE}
							y2={glassTop + glassHeight - STROKE_WIDTH / 2}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
						<line
							x1={glassLeft + glassWidth - STROKE_WIDTH / 2 - LINE_SPACE - LINE_GAP}
							y1={glassTop + STROKE_WIDTH / 2}
							x2={glassLeft + glassWidth - STROKE_WIDTH / 2 - LINE_SPACE - LINE_GAP}
							y2={glassTop + glassHeight - STROKE_WIDTH / 2}
							stroke="#6D6E70"
							strokeMiterlimit="10"
							strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
						/>
					</> :
					null}

				<rect
					x={glassLeft}
					y={glassTop}
					width={glassWidth}
					height={glassHeight}
					fill="none"
					stroke="#58595B"
					strokeWidth={STROKE_WIDTH}
					strokeMiterlimit="10"
				/>
			</g>
		);
	}

	return (
		<g>
			{view === 'OUTER' ? 
				<linearGradient id="glass-gradient-outer" gradientUnits="objectBoundingBox" x1="20%" y1="0%" x2="80%" y2="100%">
					<stop offset="0" stopColor="#ABE0F9" />
					<stop offset="0.4086" stopColor="#FFFFFF" />
					<stop offset="0.7446" stopColor="#ABE0F9" />
					<stop offset="1" stopColor="#66CDF5" />
				</linearGradient> : 
				<linearGradient id="glass-gradient-inner" gradientUnits="objectBoundingBox" x1="80%" y1="0%" x2="20%" y2="100%">
					<stop offset="0" stopColor="#ABE0F9" />
					<stop offset="0.4086" stopColor="#FFFFFF" />
					<stop offset="0.7446" stopColor="#ABE0F9" />
					<stop offset="1" stopColor="#66CDF5" />
				</linearGradient>
			}
			<rect
				x={left + STROKE_WIDTH / 2}
				y={top + STROKE_WIDTH / 2}
				width={width - STROKE_WIDTH}
				height={height - STROKE_WIDTH}
				fill="none"
				stroke="#231F20"
				strokeWidth={STROKE_WIDTH}
				strokeMiterlimit="10"
			/>
			<rect
				x={glassLeft}
				y={glassTop}
				width={glassWidth}
				height={glassHeight}
				fill={color ? color : `url(#${view === 'OUTER' ? 'glass-gradient-outer' : 'glass-gradient-inner'})`}
			/>

			{lines && lines.includes('TO_RIGHT') ? 
				<>
					<line
						x1={glassLeft + STROKE_WIDTH / 2}
						y1={glassTop + STROKE_WIDTH / 2}
						x2={glassLeft + glassWidth - STROKE_WIDTH / 2}
						y2={glassTop + 0.5 * glassHeight}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
					<line
						x1={glassLeft + glassWidth - STROKE_WIDTH / 2}
						y1={glassTop + 0.5 * glassHeight}
						x2={glassLeft + STROKE_WIDTH / 2}
						y2={glassTop + glassHeight - STROKE_WIDTH / 2}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
				</> :
				null}

			{lines && lines.includes('TO_LEFT') ? 
				<>
					<line
						x1={glassLeft + glassWidth - STROKE_WIDTH / 2}
						y1={glassTop + STROKE_WIDTH / 2}
						x2={glassLeft + STROKE_WIDTH / 2}
						y2={glassTop + 0.5 * glassHeight}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
					<line
						x1={glassLeft + STROKE_WIDTH / 2}
						y1={glassTop + 0.5 * glassHeight}
						x2={glassLeft + glassWidth - STROKE_WIDTH / 2}
						y2={glassTop + glassHeight - STROKE_WIDTH / 2}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
				</> :
				null}

			{lines && lines.includes('TO_TOP') ? 
				<>
					<line
						x1={glassLeft + STROKE_WIDTH / 2}
						y1={glassTop + glassHeight - STROKE_WIDTH / 2}
						x2={glassLeft + 0.5 * glassWidth}
						y2={glassTop + STROKE_WIDTH / 2}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
					<line
						x1={glassLeft + 0.5 * glassWidth}
						y1={glassTop + STROKE_WIDTH / 2}
						x2={glassLeft + glassWidth - STROKE_WIDTH / 2}
						y2={glassTop + glassHeight - STROKE_WIDTH / 2}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
				</> :
				null}

			{lines && lines.includes('LEFT') ? 
				<>
					<line
						x1={glassLeft + STROKE_WIDTH / 2 + LINE_SPACE}
						y1={glassTop + STROKE_WIDTH / 2}
						x2={glassLeft + STROKE_WIDTH / 2 + LINE_SPACE}
						y2={glassTop + glassHeight - STROKE_WIDTH / 2}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
					<line
						x1={glassLeft + STROKE_WIDTH / 2 + LINE_SPACE + LINE_GAP}
						y1={glassTop + STROKE_WIDTH / 2}
						x2={glassLeft + STROKE_WIDTH / 2 + LINE_SPACE + LINE_GAP}
						y2={glassTop + glassHeight - STROKE_WIDTH / 2}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
				</> :
				null}

			{lines && lines.includes('RIGHT') ? 
				<>
					<line
						x1={glassLeft + glassWidth - STROKE_WIDTH / 2 - LINE_SPACE}
						y1={glassTop + STROKE_WIDTH / 2}
						x2={glassLeft + glassWidth - STROKE_WIDTH / 2 - LINE_SPACE}
						y2={glassTop + glassHeight - STROKE_WIDTH / 2}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
					<line
						x1={glassLeft + glassWidth - STROKE_WIDTH / 2 - LINE_SPACE - LINE_GAP}
						y1={glassTop + STROKE_WIDTH / 2}
						x2={glassLeft + glassWidth - STROKE_WIDTH / 2 - LINE_SPACE - LINE_GAP}
						y2={glassTop + glassHeight - STROKE_WIDTH / 2}
						stroke="#6D6E70"
						strokeMiterlimit="10"
						strokeDasharray={view === 'OUTER' ? '4, 5' : ''}
					/>
				</> :
				null}

			<rect
				x={glassLeft}
				y={glassTop}
				width={glassWidth}
				height={glassHeight}
				fill="none"
				stroke="#58595B"
				strokeWidth={STROKE_WIDTH}
				strokeMiterlimit="10"
			/>
		</g>
	);
};
