/* eslint-disable complexity */

import React, {useLayoutEffect, useState} from 'react';
import _ from 'lodash';

import styles from './StepsList.css';
import Stream from '../libs/Stream';
import orderStream from '../streams/orderStream';
import dataStream from '../streams/dataStream';
import openStepActionStream from '../streams/openStepActionStream';
import createOrderItemActionStream from '../streams/createOrderItemActionStream';
import destroyOrderItemActionStream from '../streams/destroyOrderItemActionStream';
import selectOrderItemActionStream from '../streams/selectOrderItemActionStream';
import increaseOrderItemCountActionStream from '../streams/increaseOrderItemCountActionStream';
import decreaseOrderItemCountActionStream from '../streams/decreaseOrderItemCountActionStream';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import {OrderItem} from '../types/structure';
import Button from './Button';
import Icon from './Icon';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import config from '../config';
import useMaterialModal from '../hooks/useMaterialModal';
import findDefaultMaterial from "../utils/findDefaultMaterial";

const {ALT_NAME_HEIGHT} = config;

let i18n = new I18n();

export interface OrderItemLinkProps {
	index: number;
	orderItem: OrderItem;
	handleShow: (index: number) => void;
	handleAdd: (index: number) => void;
	handleRemove: (index: number) => void;
	handleDelete: (index: number) => void;
}

export class OrderItemLink extends React.Component<OrderItemLinkProps, {}> {
	render() {
		if (!this.props) {
			return null;
		}

		let order = orderStream.value;

		return (
			<div className={styles.orderItemLink + (this.props.index === order.currentItem ? ` ${styles.isSelected}` : '')}>
				<span className={styles.orderItemLinks}>
					<Button type="full" label="+" size="small" handleClick={this.handleAddClick} />
					<Button type="full" label="-" size="small" handleClick={this.handleRemoveClick} />
					<Button type="full" icon="bin" size="small" handleClick={this.handleDeleteClick} />
				</span>
				<span className={styles.orderItemCount}>{this.props.orderItem.count} ks</span>
				<span className={styles.orderItemName}>
					<a href="#" onClick={this.handleShowClick}>{i18n.translate `OrderStep - order item link - heading - ${this.props.index + 1}`}</a>
				</span>
				<span className={styles.orderItemLinkIcon}>
					<Icon id="cog" />
				</span>
			</div>
		);
	}

	handleShowClick = (event) => {
		event.preventDefault();

		if (this.props && this.props.handleShow) {
			this.props.handleShow(this.props.index);
		}
	};

	handleAddClick = (event) => {
		// event.preventDefault();

		if (this.props && this.props.handleAdd) {
			this.props.handleAdd(this.props.index);
		}
	};

	handleRemoveClick = (event) => {
		// event.preventDefault();

		if (this.props && this.props.handleRemove) {
			this.props.handleRemove(this.props.index);
		}
	};

	handleDeleteClick = (event) => {
		// event.preventDefault();

		if (this.props && this.props.handleDelete) {
			this.props.handleDelete(this.props.index);
		}
	};
}


export default class StepsList extends React.Component<{}, {}> {
	onOrderStream: Stream<{}>;
	onLanguageStream: Stream<{}>;
	
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			height: 0
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}


	componentDidMount() {
		this.onOrderStream = orderStream.on(() => {
			requestAnimationFrame(() => this.forceUpdate());
		});
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.onLanguageStream = languageStream.on(() => {
			// console.log('StepsList 1');
			// requestAnimationFrame(() => {
			//	 console.log('StepsList 2');
			this.forceUpdate();
			// });
		});
	}

	componentWillUnmount() {
		this.onOrderStream.end.push(true);
		this.onLanguageStream.end.push(true);
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({
			width: window.innerWidth,
			height: window.innerHeight
		});
	}

	render() {
		let order = orderStream.value;
		let window;
		let windowType;
		let material;
		let profile;
		let glass;
		let color;
		let sunblind;
		let innerWindowsill;
		let outerWindowsill;
		let accessories;


		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		if (orderItem) {
			window = orderItem.window;
			windowType = window ? _.find(dataStream.value.windowTypes, {id: window.typeId}) : undefined;
			
			if (!orderItem.material && orderItem.window && orderItem.window.materialId) {
				orderItem.materialId = orderItem.window.materialId;
				orderItem.material = findDefaultMaterial(dataStream.value.materials, orderItem.window.materialId);
			}
			material = orderItem.material;
			
			profile = orderItem.profile;
			glass = orderItem.glass;
			color = orderItem.color;
			sunblind = orderItem.sunblind;
			innerWindowsill = orderItem.innerWindowsill;
			outerWindowsill = orderItem.outerWindowsill;
			accessories = orderItem.accessories;
		}

		const step1 =
			<li key="1" className={`${styles.step} ${styles.hasOverlay} ${material ? '' : styles.isEmpty} ${styles.stepNo1}`}>
				<div className={styles.stepWrapper}>
					<p className={styles.stepHeading}>{i18n.translate `StepsList - material - heading`}</p>

					<div className={styles.stepContent}>
						{material ? <p>{material.name[languageStream.value.current]}</p> : null}
						<p className={styles.stepButtonAlt}>
							<Button
								type="full"
								size="small"
								label={i18n.translate `StepsList - material - button`}
								handleClick={this.handleMaterialStepClick}
							/>
						</p>
					</div>

					<p className={styles.stepButton}>
						<Button
							type="outlined"
							size="small"
							color="inverse"
							label={i18n.translate `StepsList - material - button`}
							/* isDisabled={!orderItem || !orderItem.material} */
							handleClick={this.handleMaterialStepClick}
						/>
					</p>
				</div>
			</li>;
		const step2 =
			<li key="2" className={`${styles.step} ${styles.hasOverlay} ${window ? '' : styles.isEmpty} ${styles.stepNo2}`}>
				<div className={styles.stepWrapper}>
					<p className={styles.stepHeading}>{i18n.translate `StepsList - window type - heading`}</p>

					<div className={styles.stepContent}>
						{window && windowType ?
							<p>
								{windowType.altName && window.height >= ALT_NAME_HEIGHT ?
									windowType.altName[languageStream.value.current] :
									windowType.name[languageStream.value.current]}
							</p> :
							null}
						{window && windowType && windowType.hasLeftRightVariants ?
							<p>
								{i18n.translate `windowType - variant`}:{' '}
								{window.variant === 1 ?
									i18n.translate `windowType - variant - left` :
									i18n.translate `windowType - variant - right`}
							</p> :
							null}
						<p className={styles.stepButtonAlt}>
							<Button
								type="full"
								size="small"
								label={i18n.translate `StepsList - window type - button`}
								handleClick={this.handleWindowStepClick}
							/>
						</p>
					</div>

					<p className={styles.stepButton}>
						<Button
							type="outlined"
							size="small"
							color="inverse"
							label={i18n.translate `StepsList - window type - button`}
							isDisabled={!orderItem?.material}
							handleClick={this.handleWindowStepClick}
						/>
					</p>
				</div>
			</li>;

		const step3 =
			<li key="3" className={`${styles.step} ${styles.hasOverlay} ${profile ? '' : styles.isEmpty} ${styles.stepNo3}`}>
				<div className={styles.stepWrapper}>
					<p className={styles.stepHeading}>{i18n.translate `StepsList - profile - heading`}</p>

					<div className={styles.stepContent}>
						{profile ?
							<p>
								{profile.id === 1 && windowType && (windowType.id === 13) ?
									profile.altName[languageStream.value.current] :
									profile.name[languageStream.value.current]}
							</p> :
							null}
						<p className={styles.stepButtonAlt}>
							<Button
								type="full"
								size="small"
								label={i18n.translate `StepsList - profile - button`}
								handleClick={this.handleProfileStepClick}
							/>
						</p>
					</div>

					<p className={styles.stepButton}>
						<Button
							type="outlined"
							size="small"
							color="inverse"
							label={i18n.translate `StepsList - profile - button`}
							isDisabled={!orderItem || !orderItem.window}
							handleClick={this.handleProfileStepClick}
						/>
					</p>
				</div>
			</li>;
		const step4 =
			<li key="4" className={`${styles.step} ${styles.hasOverlay} ${glass ? '' : styles.isEmpty} ${styles.stepNo4}`}>
				<div className={styles.stepWrapper}>
					<p className={styles.stepHeading}>{i18n.translate `StepsList - glass - heading`}</p>

					<div className={styles.stepContent}>
						{glass ? <p>{glass.name[languageStream.value.current]}</p> : null}
						<p className={styles.stepButtonAlt}>
							<Button
								type="full"
								size="small"
								label={i18n.translate `StepsList - glass - button`}
								handleClick={this.handleGlassStepClick}
							/>
						</p>
					</div>

					<p className={styles.stepButton}>
						<Button
							type="outlined"
							size="small"
							color="inverse"
							label={i18n.translate `StepsList - glass - button`}
							isDisabled={!orderItem || !orderItem.window}
							handleClick={this.handleGlassStepClick}
						/>
					</p>
				</div>
			</li>;
		const step5 =
			<li key="5" className={`${styles.step} ${styles.hasOverlay} ${color ? '' : styles.isEmpty} ${styles.stepNo5}`}>
				<div className={styles.stepWrapper}>
					<p className={styles.stepHeading}>{i18n.translate `StepsList - color - heading`}</p>

					<div className={styles.stepContent}>
						{color ?
							<p>
								{color.name[languageStream.value.current]} (
								{color.sidesCount > 1 ? i18n.translate `color - two sides` : i18n.translate `color - one side`})
							</p> :
							null}
						<p className={styles.stepButtonAlt}>
							<Button
								type="full"
								size="small"
								label={i18n.translate `StepsList - color - button`}
								handleClick={this.handleColorStepClick}
							/>
						</p>
					</div>

					<p className={styles.stepButton}>
						<Button
							type="outlined"
							size="small"
							color="inverse"
							label={i18n.translate `StepsList - color - button`}
							isDisabled={!orderItem || !orderItem.window}
							handleClick={this.handleColorStepClick}
						/>
					</p>
				</div>
			</li>;
		const step6 =
			<li key="6" className={`${styles.step} ${styles.hasOverlay} ${sunblind || innerWindowsill || outerWindowsill || accessories ? '' : styles.isEmpty} ${styles.stepNo6}`}>
				<div className={styles.stepWrapper}>
					<p className={styles.stepHeading}>{i18n.translate `StepsList - accessories - heading`}</p>

					<div className={styles.stepContent}>
						{sunblind ?
							<p>
								{i18n.translate `AccessoriesStep - heading 6`}: {sunblind.color[languageStream.value.current]}
							</p> :
							null}
						{innerWindowsill ?
							<p>
								{i18n.translate `AccessoriesStep - heading 7`}:{' '}
								{innerWindowsill.color?.[languageStream.value.current] ?? ''}{' '}
								{` ${innerWindowsill.width} mm × ${innerWindowsill.depth} mm`}
							</p> :
							null}
						{outerWindowsill ?
							<p>
								{i18n.translate `AccessoriesStep - heading 8`}:{' '}
								{outerWindowsill.color?.[languageStream.value.current] ?? ''}{' '}
								{` ${outerWindowsill.width} mm × ${outerWindowsill.depth} mm`}
							</p> :
							null}
						{accessories ?
							accessories.map((accessory, index) => <p key={index}>{accessory.name[languageStream.value.current]}</p>
							) :
							null}
						<p className={styles.stepButtonAlt}>
							<Button
								type="full"
								size="small"
								label={i18n.translate `StepsList - accessories - button`}
								handleClick={this.handleAccessoriesStepClick}
							/>
						</p>
					</div>

					<p className={styles.stepButton}>
						<Button
							type="outlined"
							size="small"
							color="inverse"
							label={i18n.translate `StepsList - accessories - button`}
							isDisabled={!orderItem || !orderItem.window}
							handleClick={this.handleAccessoriesStepClick}
						/>
					</p>
				</div>
			</li>;
		const step7 =
			<li key="7" className={`${styles.step} ${order.items.length ? '' : styles.isEmpty} ${styles.stepNo7}`}>
				<div className={styles.stepWrapper}>
					<p className={styles.stepHeading}>{i18n.translate `StepsList - windows - heading`}</p>

					<div className={styles.stepContent}>
						<ul className={styles.stepList}>
							{order.items.length ?
								order.items.map((orderItem, index) => <li key={index}>
									<OrderItemLink
										index={index}
										orderItem={orderItem}
										handleShow={this.handleShowOrderItem}
										handleAdd={this.handleAddOrderItem}
										handleRemove={this.handleRemoveOrderItem}
										handleDelete={this.handleDeleteOrderItem}
									/>
								</li>
								) : null}
						</ul>

						{orderStream.value.items && orderStream.value.items.length ?
							<p className={styles.stepButton}>
								<Button
									type="full"
									size="small"
									label={i18n.translate `StepsList - windows - button`}
									overlayLabel={i18n.translate `StepsList - windows - button overlay`}
									handleClick={this.handleAddNewOrderItem}
								/>
								{/* <Button type="outlined" color="secondary" label={i18n.translate `StepsList - windows - button`} handleClick={this.handleAddNewOrderItem} /> */}
							</p> :
							null}
					</div>
				</div>
			</li>;
		const step8 =
			<li key="8" className={`${styles.step} ${order.items.length ? '' : styles.isEmpty} ${styles.stepNo8}`}>
				<div className={styles.stepWrapper}>
					<p className={styles.stepHeading}>{i18n.translate `StepsList - order - heading`}</p>

					<div className={styles.stepContent}>
						{/* <p>{i18n.translate `StepsList - order - info`}</p>*/}
						{orderStream.value.items.length ?
							<p className={styles.stepButton}>
								<Button
									type="full"
									label={i18n.translate `StepsList - order - button`}
									isDisabled={!orderStream.value.items.length}
									handleClick={this.handleOrderStepClick}
								/>
							</p> :
							null}
					</div>
				</div>
			</li>;
		if (this.state.width > 640 && this.state.width < 1200) {
			return (
				<div className={`${styles.stepSlideWrapper}`}>
					<Splide
						options={{
							perPage: 4,
							perMove: 1,
							gap: '1rem',
							breakpoints: {
								1024: {
									perPage: 3
								},
								768: {
									perPage: 2
								}

							}
						}}
					>
						<SplideSlide>
							{step1}
						</SplideSlide>
						<SplideSlide>
							{step2}
						</SplideSlide>
						<SplideSlide>
							{step3}
						</SplideSlide>
						<SplideSlide>
							{step4}
						</SplideSlide>
						<SplideSlide>
							{step5}
						</SplideSlide>
						<SplideSlide>
							{step6}
						</SplideSlide>
						<SplideSlide>
							{step7}
						</SplideSlide>
						<SplideSlide>
							{step8}
						</SplideSlide>
					</Splide>
				</div>
			);
		}

		return (
			<nav className={styles.root}>
				<ol className={styles.steps}>
					{step1}
					{step2}
					{step3}
					{step4}
					{step5}
					{step6}
					{step7}
					{step8}
				</ol>
			</nav>
		);
	}

	handleWindowStepClick = () => {
		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		if (!orderItem) {
			// return;
			createOrderItemActionStream.push(true);
		}

		openStepActionStream.push('window');
		// this.props.setMaterialModal(false);
	};

	handleMaterialStepClick = () => {
		/* let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		console.log("orderItem", orderItem);
		console.log("orderStream.value.items", orderStream.value.items);
		console.log("orderStream.value.currentItem", orderStream.value.currentItem);
		if (!orderItem || !orderItem.window) {
			return;
		} */

		openStepActionStream.push('material');
	};

	handleProfileStepClick = () => {
		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		if (!orderItem || !orderItem.window) {
			return;
		}

		openStepActionStream.push('profile');
	};

	handleGlassStepClick = () => {
		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		if (!orderItem || !orderItem.window) {
			return;
		}

		openStepActionStream.push('glass');
	};

	handleColorStepClick = () => {
		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		if (!orderItem || !orderItem.window) {
			return;
		}

		openStepActionStream.push('color');
	};

	handleAccessoriesStepClick = () => {
		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		if (!orderItem || !orderItem.window) {
			return;
		}

		openStepActionStream.push('accessories');
	};

	handleOrderStepClick = () => {
		openStepActionStream.push('order');
	};

	handleAddNewOrderItem = () => {
		createOrderItemActionStream.push(true);
	};

	handleDeleteOrderItem = (index) => {
		destroyOrderItemActionStream.push(index);
	};

	handleShowOrderItem = (index) => {
		selectOrderItemActionStream.push(index);
	};

	handleAddOrderItem = (index) => {
		increaseOrderItemCountActionStream.push(index);
	};

	handleRemoveOrderItem = (index) => {
		decreaseOrderItemCountActionStream.push(index);
	};
}
