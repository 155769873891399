import _ from 'lodash';

import config from '../config';
import dataStream from '../streams/dataStream';

const MM_IN_M = 1000;
const MM_IN_CM = 10;

export default function getOrderItemPrice(orderItem) {
	let price = 0;
	let totalPrice = 0;
	let windowPrice = 0;
	let accessoriesPrice = 0;
	let externalAccessoriesPrice = 0;
	let weight = 0;
	let totalWeight = 0;
	
	// console.log('getorderprice', orderItem);
	if (!(orderItem.window && orderItem.material && orderItem.profile && orderItem.glass && orderItem.color)) {
		return {price, totalPrice, windowPrice, accessoriesPrice, externalAccessoriesPrice};
	}
	
	let profilePriceModifier = 1;
	if (_.isFinite(orderItem.profile.priceModifier)) {
		let {windowTypes} = dataStream.value;
		let windowType = orderItem && orderItem.window ? _.find(windowTypes, {id: orderItem.window.typeId}) : undefined;
		
		// we can set price modifier for profile only if window type has no predefined profile (e.g. windows)
		if (!(windowType && _.isFinite(windowType.profileId))) {
			profilePriceModifier = orderItem.profile.priceModifier;
		}
	}
	
	let colorPriceModifier = 1;
	orderItem.color.priceModifiers.forEach((priceModifier) => {
		if (priceModifier.profileIds.includes(orderItem.profile.id) && priceModifier.sidesCounts.includes(orderItem.color.sidesCount)) {
			colorPriceModifier = priceModifier.value;
		}
	});
	
	// console.log('prices', [orderItem.window.price, config.COEFFICIENT, profilePriceModifier, colorPriceModifier, orderItem.window.width / MM_IN_M, orderItem.window.height / MM_IN_M, orderItem.glass.price, orderItem.profile.rebate, config.MARGIN, config.ONE_PLN_IN_CZK]);
	
	if (orderItem && orderItem.material) {
		switch (orderItem.material.id) {
			case 1:
			default:
			// (((((Cena z ceníku * "koeficient navýšení nákupní ceny“) -/+ Sleva/Příplatek na Systém) + příplatek za barvu) + SKLO) * RABAT) * KURZ * MARŽE
				windowPrice = (orderItem.window.price * config.COEFFICIENT * profilePriceModifier * colorPriceModifier + orderItem.window.width / MM_IN_M * (orderItem.window.height / MM_IN_M) * orderItem.glass.price) *
				orderItem.profile.rebate *
				config.MARGIN *
				config.ONE_PLN_IN_CZK;
				break;
			case 2:
			// ((cena ceník) * (koeficient základní ceny) + (sklo)*(sleva WITRAZ))* (kurz) * (marže koeficient) * 1,21 (DPH CZ)
				windowPrice = (orderItem.window.price * config.COEFFICIENT_WOOD * profilePriceModifier * colorPriceModifier + orderItem.window.width / MM_IN_M * (orderItem.window.height / MM_IN_M) * orderItem.glass.price) *
				config.WITRAZ *
				config.MARGIN_WOOD *
				config.ONE_PLN_IN_CZK;
				break;
		}
	}
	// console.log('windowPrice', windowPrice);
	
	if (orderItem.sunblind && _.isFinite(orderItem.sunblind.price)) {
		accessoriesPrice += orderItem.sunblind.price;
	}
	
	if (orderItem.innerWindowsill && _.isFinite(orderItem.innerWindowsill.price) && _.isFinite(orderItem.innerWindowsill.width)) {
		accessoriesPrice += orderItem.innerWindowsill.price * orderItem.innerWindowsill.width / MM_IN_CM;
	}
	
	if (orderItem.outerWindowsill && _.isFinite(orderItem.outerWindowsill.price) && _.isFinite(orderItem.outerWindowsill.width)) {
		accessoriesPrice += orderItem.outerWindowsill.price * orderItem.outerWindowsill.width / MM_IN_CM;
	}
	
	if (orderItem.accessories) {
		orderItem.accessories.forEach((accessory) => {
			if (_.isFinite(accessory.price)) {
				externalAccessoriesPrice += accessory.price * accessory.count;
			}
		});
	}
	
	price = windowPrice + accessoriesPrice + externalAccessoriesPrice;
	totalPrice = price * orderItem.count;
	weight = orderItem.window.width / MM_IN_M * (orderItem.window.height / MM_IN_M) * config.WEIGHT_OF_M2_IN_KG;
	totalWeight = weight * orderItem.count;
	
	return {price, totalPrice, windowPrice, accessoriesPrice, externalAccessoriesPrice, weight, totalWeight};
}
