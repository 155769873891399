import {curry1} from './curry1';
import {curry2} from './curry2';


/**
 * Optimized internal three-arity curry function.
 *
 * @private
 * @category Function
 * @param {Function} fn The function to curry.
 * @return {Function} The curried function.
 */
export function curry3(fn) {
	return function fn3(a, b, c) {
		if (arguments.length === 0) {
			return fn3;
		}

		if (arguments.length === 1) {
			return curry2((_b, _c) => fn(a, _b, _c));
		}

		if (arguments.length === 2) {
			return curry1((_c) => fn(a, b, _c));
		}

		return fn(a, b, c);
	};
}
