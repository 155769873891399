import _ from 'lodash';
import {Material, Materials, WindowType} from '../types/data';

export default function findDefaultMaterial(materials: Materials, materialId ?: number): Material {
	if (_.isFinite(materialId)) {
		let material = _.find(materials, {id: materialId});
		
		if (material) {
			return _.cloneDeep(material);
		}
		
		return _.cloneDeep(materials[0]);
	}
	
	return _.cloneDeep(materials[0]);
}
