/* eslint-disable complexity */

import React, {memo} from 'react';

import {Frame} from './windows/Frame';
import {Glass} from './windows/Glass';
import {Handle} from './windows/Handle';
import {Hinge} from './windows/Hinge';

const GLASS_GAP = 26;
const GLASS_GAP_ALT = 11;
const STROKE_WIDTH = 2;
const PADDING = 2;

export let Window = memo(({width, height, color, type, variant, view, onResize, glassColor}) => {
	const FRAME_GLASS_SPACE = view === 'OUTER' ? 7 * 8 : 5 * 8;

	let windowElements = null;
	let x = PADDING;
	let y = PADDING;

	if (type === 'F+F') {
		let glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP) * 0.5;
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={false}
				lines={[]}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={false}
				lines={[]}
			/>
		</>;
	}

	if (type === 'F') {
		let glassWidth = width - FRAME_GLASS_SPACE * 2;
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={false}
				lines={[]}
			/>
		</>;
	}

	if (type === 'O') {
		let glassWidth = width - FRAME_GLASS_SPACE * 2;
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		if (variant === 1) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_RIGHT'] : ['TO_LEFT']}
			/>

			{view === 'INNER' ? 
				<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_LEFT" /> :
				null}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
				null}
		</>;
		}

		if (variant === 2) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_LEFT'] : ['TO_RIGHT']}
			/>

			{view === 'INNER' ? 
				<Handle left={x + FRAME_GLASS_SPACE} top={height / 2} color={color} orientation="TO_RIGHT" /> :
				null}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE + glassWidth} top={y + FRAME_GLASS_SPACE} color={color} type="A_RIGHT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth}
					top={y + FRAME_GLASS_SPACE + glassHeight}
					color={color}
					type="B_RIGHT"
				/> :
				null}
		</>;
		}
	}

	if (type === 'OS') {
		let glassWidth = width - FRAME_GLASS_SPACE * 2;
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		if (variant === 1) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_RIGHT', 'TO_TOP'] : ['TO_LEFT', 'TO_TOP']}
			/>

			{view === 'INNER' ? 
				<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_LEFT" /> :
				null}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
				null}
		</>;
		}

		if (variant === 2) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_LEFT', 'TO_TOP'] : ['TO_RIGHT', 'TO_TOP']}
			/>

			{view === 'INNER' ? 
				<Handle left={x + FRAME_GLASS_SPACE} top={height / 2} color={color} orientation="TO_RIGHT" /> :
				null}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE + glassWidth} top={y + FRAME_GLASS_SPACE} color={color} type="A_RIGHT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth}
					top={y + FRAME_GLASS_SPACE + glassHeight}
					color={color}
					type="B_RIGHT"
				/> :
				null}
		</>;
		}
	}

	if (type === 'S') {
		let glassWidth = width - FRAME_GLASS_SPACE * 2;
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_TOP']}
			/>

			{view === 'INNER' ? 
				<Handle left={width / 2} top={y + FRAME_GLASS_SPACE} color={color} orientation="TO_BOTTOM" /> :
				null}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="C_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth}
					top={y + FRAME_GLASS_SPACE + glassHeight}
					color={color}
					type="C_RIGHT"
				/> :
				null}
		</>;
	}

	if (type === 'ST') {
		let glassWidth = (width - FRAME_GLASS_SPACE * 2 + STROKE_WIDTH) * 0.5;
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		if (variant === 1) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_RIGHT', 'TO_TOP'] : ['TO_RIGHT', 'RIGHT']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth - STROKE_WIDTH * 1}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_LEFT', 'LEFT'] : ['TO_LEFT', 'TO_TOP']}
			/>

			{view === 'INNER' ? 
				<Handle
					left={x + FRAME_GLASS_SPACE + glassWidth - STROKE_WIDTH * 0.5}
					top={height / 2}
					color={color}
					orientation="TO_LEFT"
				/> :
				null}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth * 2 - STROKE_WIDTH * 1}
					top={y + FRAME_GLASS_SPACE}
					color={color}
					type="A_RIGHT"
				/> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth * 2 - STROKE_WIDTH * 1}
					top={y + FRAME_GLASS_SPACE + glassHeight}
					color={color}
					type="B_RIGHT"
				/> :
				null}
		</>;
		}

		if (variant === 2) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_RIGHT', 'RIGHT'] : ['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth - STROKE_WIDTH * 1}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_LEFT', 'TO_TOP'] : ['TO_LEFT', 'LEFT']}
			/>

			{view === 'INNER' ? 
				<Handle
					left={x + FRAME_GLASS_SPACE + glassWidth - STROKE_WIDTH * 0.5}
					color={color}
					top={height / 2}
					orientation="TO_RIGHT"
				/> :
				null}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth * 2 - STROKE_WIDTH * 1}
					top={y + FRAME_GLASS_SPACE}
					color={color}
					type="A_RIGHT"
				/> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth * 2 - STROKE_WIDTH * 1}
					top={y + FRAME_GLASS_SPACE + glassHeight}
					color={color}
					type="B_RIGHT"
				/> :
				null}
		</>;
		}
	}

	if (type === 'OS+F') {
		let glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP_ALT) * 0.5;
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		if (variant === 1) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={view === 'INNER'}
				lines={view === 'INNER' ? ['TO_RIGHT', 'TO_TOP'] : []}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={view !== 'INNER'}
				lines={view === 'INNER' ? [] : ['TO_LEFT', 'TO_TOP']}
			/>

			{view === 'INNER' ? 
				<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_LEFT" /> :
				null}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
				null}
		</>;
		}

		if (variant === 2) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={view !== 'INNER'}
				lines={view === 'INNER' ? [] : ['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={view === 'INNER'}
				lines={view === 'INNER' ? ['TO_LEFT', 'TO_TOP'] : []}
			/>

			{view === 'INNER' ? 
				<Handle
					left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
					top={height / 2}
					color={color}
					orientation="TO_RIGHT"
				/> :
				null}

			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
					top={y + FRAME_GLASS_SPACE}
					color={color}
					type="A_RIGHT"
				/> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
					top={y + FRAME_GLASS_SPACE + glassHeight}
					color={color}
					type="B_RIGHT"
				/> :
				null}
		</>;
		}
	}

	if (type === 'OS+OS') {
		let glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP) * 0.5;
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>

			{view === 'INNER' ? 
				<Handle
					left={x + FRAME_GLASS_SPACE + glassWidth - 22}
					top={height / 2 - 28}
					color={color}
					orientation="TO_BOTTOM"
				/> :
				null}
			{view === 'INNER' ? 
				<Handle
					left={x + FRAME_GLASS_SPACE + GLASS_GAP + glassWidth + 22}
					top={height / 2 - 28}
					color={color}
					orientation="TO_BOTTOM"
				/> :
				null}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP}
					top={y + FRAME_GLASS_SPACE}
					color={color}
					type="A_RIGHT"
				/> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP}
					top={y + FRAME_GLASS_SPACE + glassHeight}
					color={color}
					type="B_RIGHT"
				/> :
				null}
		</>;
	}

	if (type === 'OS+OS+OS') {
		let glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP - GLASS_GAP_ALT) * (1 / 3);
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		if (variant === 1 && view === 'INNER') {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>

			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth - 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>
			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP - 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>
			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT + 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>

			<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" />
			<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" />

			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				color={color}
				type="A_LEFT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE + glassHeight}
				color={color}
				type="B_LEFT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				color={color}
				type="A_RIGHT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE + glassHeight}
				color={color}
				type="B_RIGHT"
			/>
		</>;
		}

		if (variant === 1 && view === 'OUTER') {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>
		</>;
		}

		if (variant === 2 && view === 'INNER') {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>

			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth - 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>
			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT + 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>
			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP + 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>

			<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" />
			<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" />
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				color={color}
				type="A_RIGHT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE + glassHeight}
				color={color}
				type="B_RIGHT"
			/>

			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP_ALT + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				color={color}
				type="A_RIGHT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP_ALT + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE + glassHeight}
				color={color}
				type="B_RIGHT"
			/>
		</>;
		}

		if (variant === 2 && view === 'OUTER') {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>
		</>;
		}
	}

	if (type === 'ST+OS') {
		let glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP - GLASS_GAP_ALT) * (1 / 3);
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		if (variant === 1 && view === 'INNER') {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>

			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth - 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>
			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP + 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>

			<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" />
			<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" />
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				color={color}
				type="A_RIGHT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE + glassHeight}
				color={color}
				type="B_RIGHT"
			/>

			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP_ALT + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				color={color}
				type="A_RIGHT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP_ALT + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE + glassHeight}
				color={color}
				type="B_RIGHT"
			/>
		</>;
		}

		if (variant === 1 && view === 'OUTER') {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>
		</>;
		}

		if (variant === 2 && view === 'INNER') {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				view={view}
				frame={true}
				lines={['TO_RIGHT']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>

			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth - 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>
			<Handle
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT + 22}
				top={height / 2 - 28}
				color={color}
				orientation="TO_BOTTOM"
			/>

			<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" />
			<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" />

			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				color={color}
				type="A_LEFT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE + glassHeight}
				color={color}
				type="B_LEFT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				color={color}
				type="A_RIGHT"
			/>
			<Hinge
				left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE + glassHeight}
				color={color}
				type="B_RIGHT"
			/>
		</>;
		}

		if (variant === 2 && view === 'OUTER') {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_RIGHT', 'TO_TOP']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT']}
			/>
			<Glass
				left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight}
				color={glassColor}
				view={view}
				frame={true}
				lines={['TO_LEFT', 'TO_TOP']}
			/>
		</>;
		}
	}

	if (type === 'TD-TROCAL' || type === 'TD-SOFTLINE70' || type === 'TD-SOFTLINE82' || type === 'VD-TROCAL' || type === 'VD-SOFTLINE70' || type === 'VD-SOFTLINE82') {
		let glassWidth = width - FRAME_GLASS_SPACE * 2;
		let glassHeight = height - FRAME_GLASS_SPACE * 2;

		if (variant === 1) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight + FRAME_GLASS_SPACE - STROKE_WIDTH * 4}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_RIGHT'] : ['TO_LEFT']}
			/>

			{view === 'INNER' ? 
				<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_LEFT" /> : 
				<Handle left={x + FRAME_GLASS_SPACE} top={height / 2} color={color} orientation="TO_RIGHT" />
			}

			{view === 'INNER' ? 
				<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="D_LEFT_TOP" /> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE}
					top={y + FRAME_GLASS_SPACE + glassHeight / 2}
					color={color}
					type="D_LEFT_MIDDLE"
				/> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE}
					top={y + FRAME_GLASS_SPACE + glassHeight}
					color={color}
					type="D_LEFT_BOTTOM"
				/> :
				null}
		</>;
		}

		if (variant === 2) {
			windowElements = 
		<>
			<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
			<Glass
				left={x + FRAME_GLASS_SPACE}
				top={y + FRAME_GLASS_SPACE}
				width={glassWidth}
				height={glassHeight + FRAME_GLASS_SPACE - STROKE_WIDTH * 4}
				color={glassColor}
				view={view}
				frame={true}
				lines={view === 'INNER' ? ['TO_LEFT'] : ['TO_RIGHT']}
			/>

			{view === 'INNER' ? 
				<Handle left={x + FRAME_GLASS_SPACE} top={height / 2} color={color} orientation="TO_RIGHT" /> : 
				<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_LEFT" />
			}

			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth}
					top={y + FRAME_GLASS_SPACE}
					color={color}
					type="D_RIGHT_TOP"
				/> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth}
					top={y + FRAME_GLASS_SPACE + glassHeight / 2}
					color={color}
					type="D_RIGHT_MIDDLE"
				/> :
				null}
			{view === 'INNER' ? 
				<Hinge
					left={x + FRAME_GLASS_SPACE + glassWidth}
					top={y + FRAME_GLASS_SPACE + glassHeight}
					color={color}
					type="D_RIGHT_BOTTOM"
				/> :
				null}
		</>;
		}
	}

	let svgStyle = `
	path, line, rect {
		vector-effect: non-scaling-stroke;
	}
	text {
		font-family: Arial;
		font-size: 20px;
	}
	`;

	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={`0 0 ${width + 2 * PADDING} ${height + 2 * PADDING}`}
		>
			<style>{svgStyle}</style>
			{windowElements}
		</svg>
	);
});
