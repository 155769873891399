import React from 'react';

import styles from './ColorStep.css';
import openStepActionStream from '../streams/openStepActionStream';
import dataStream from '../streams/dataStream';
import orderStream from '../streams/orderStream';
import {Color} from '../types/data';
import selectColorActionStream from '../streams/selectColorActionStream';
import Button from './Button';
import Icon from './Icon';
import ButtonGroup from './ButtonGroup';
import link from '../utils/link';
import getOrderItemPrice from '../utils/getOrderItemPrice';
import Stream from '../libs/Stream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import getConvertedPrice from '../utils/getConvertedPrice';
import config from '../config';

const {VAT_MODIFIER} = config;

let i18n = new I18n();

export interface ColorDetailProps {
	color: Color;
	sidesCount: number;
	isSelected: boolean;
	handleClick: (color: Color, sidesCount: number) => void;
}

export class ColorDetail extends React.Component<ColorDetailProps, {}> {
	render() {
		let order = orderStream.value;
		let orderItem;

		if (order.currentItem !== null) {
			orderItem = order.items[order.currentItem];
		}

		if (!this.props) {
			return;
		}

		let {windowPrice: currentWindowPrice} = getOrderItemPrice(orderItem);
		let {windowPrice: nextWindowPrice} = getOrderItemPrice(
			{...orderItem,
				color: {
					id: this.props.color.id,
					name: this.props.color.name,
					category: this.props.color.category,
					sidesCount: this.props.sidesCount,
					priceModifiers: this.props.color.priceModifiers
				}}
		);
		let delta = getConvertedPrice((nextWindowPrice - currentWindowPrice) * VAT_MODIFIER[languageStream.value.current]);

		return (
			<div
				className={styles.colorDetail + (this.props.isSelected ? ` ${styles.isSelected}` : '')}
				onClick={this.handleClick}
			>
				<figure className={styles.colorDetailPreview}>
					{this.props.color.image ? <img src={link('assets', this.props.color.image)} alt={this.props.color.name[languageStream.value.current]} /> : null}
				</figure>
				<div className={styles.colorDetailOverlay}>
					<p className={styles.colorDetailOverlayHeading}>{this.props.color.name[languageStream.value.current]}</p>
					<p className={styles.colorDetailOverlayPriceComparison}>
						{delta >= 0 ?
							i18n.translate `ColorStep - color detail - positive price - ${delta}:c` :
							i18n.translate `ColorStep - color detail - negative price - ${delta * -1}:c`}
					</p>
					{this.props.color.image ? 
						<figure className={styles.colorDetailOverlayPhoto}>
							<img src={link('assets', this.props.color.image)} alt="" />
						</figure> :
						null}
				</div>
			</div>
		);
	}

	handleClick = (event) => {
		if (this.props) {
			this.props.handleClick(this.props.color, this.props.sidesCount);
		}
	};
}

export interface ColorStepProps {
	isOpen: boolean;
}

export default class ColorStep extends React.Component<ColorStepProps, {}> {
	onLanguageStream: Stream<{}>;

	state = {
		sidesCount: 2
	};

	componentDidMount() {
		this.onLanguageStream = languageStream.on(() => {
			// console.log('ColorStep 1');
			// requestAnimationFrame(() => {
			// console.log('ColorStep 2');
			this.forceUpdate();
			// });
		});
	}

	componentWillUnmount() {
		this.onLanguageStream.end.push(true);
	}

	render() {
		let {colors, colorCategories} = dataStream.value;
		
		let orderItem = orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];
		if (!orderItem) {
			return null;
		}

		let filteredColors = colors.filter(
			(color) => orderItem && orderItem.material && color.materialIds.includes(orderItem.material.id) && orderItem.profile && color.profileIds.includes(orderItem.profile.id)
		);
		
		let baseUrl = 'okna-hned.cz';
		switch (languageStream.value.current) {
			case 'sk-SK':
				baseUrl = 'okna-hned.sk';
				break;
			case 'de-DE':
				baseUrl = 'fenster-jetzt.de';
				break;
			case 'de-AT':
				baseUrl = 'fenster-jetzt.at';
				break;
			case 'hu-HU':
				baseUrl = 'ablakok-most.hu';
				break;
		}
		
		let swatchLink = '56-barvy-a-dekory-profilu-trocal';
		if (orderItem && orderItem.profile) {
			switch (orderItem.profile.id) {
				case 1:
					swatchLink = '56-barvy-a-dekory-profilu-trocal';
					break;
				case 2:
				case 3:
					swatchLink = '57-barvy-a-dekory-profilu-veka';
					break;
				case 4:
					swatchLink = '59-barvy-a-dekory-drevenych-oken-eurookna';
					break;
			}
		}

		return (
			<section className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}>
				<div className={styles.wrapper}>
					<h2 className={styles.heading}>{i18n.translate `ColorStep - heading 1`}</h2>
					<span className={styles.closeButton}>
						<Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick} />
					</span>
					
					<div>
						<p className={styles.colorVariantsList}>
							{orderItem.material && orderItem.material.id === 1 ? <span className={styles.colorVariant} onClick={this.handleSingleSideClick}>
								<span className={styles.colorVariantBox + (this.state.sidesCount === 1 ? ` ${styles.isSelected}` : '')}>
									<Icon id="cross" size="small" />
								</span>
								<span>{i18n.translate `ColorStep - sides 1`}</span>
							</span> : null}
							
							<span className={styles.colorVariant} onClick={this.handleDoubleSideClick}>
								<span className={styles.colorVariantBox + (this.state.sidesCount === 2 ? ` ${styles.isSelected}` : '')}>
									<Icon id="cross" size="small" />
								</span>
								<span>{i18n.translate `ColorStep - sides 2`}</span>
							</span>
						</p>
						
						{this.state.sidesCount === 1 ? <p className={styles.colorVariantsWarning}>{i18n.translate `ColorStep - warning`}</p> : null}
						
						<div className={styles.panels}>
							{this.state.sidesCount === 1 ?
								<div>
									{colorCategories
										.filter((colorCategory) => colorCategory.id !== null)
										.map((colorCategory, index, colorCategories) => <div
											key={index}
											className={styles.colorCategory + (index === colorCategories.length - 1 ? ` ${styles.isLast}` : '')}
										>
											<ul className={styles.colorsList}>
												{filteredColors.map((color, index) => {
													if (colorCategory.id !== color.category) {
														return null;
													}
													
													let hasSides = false;
													
													for (let i = 0; i < color.priceModifiers.length; i++) {
														if (color.priceModifiers[i].sidesCounts.includes(1)) {
															hasSides = true;
															
															break;
														}
													}
													
													if (!hasSides) {
														return null;
													}
													
													return (
														<li key={index}>
															<ColorDetail
																color={color}
																sidesCount={1}
																isSelected={
																	orderItem && orderItem.color && color.id === orderItem.color.id && orderItem.color.sidesCount === 1
																}
																handleClick={this.handleSelectColor}
															/>
														</li>
													);
												})}
											</ul>
										</div>
										)}
								</div> :
								null}
							
							{this.state.sidesCount === 2 ?
								<div>
									{colorCategories.map((colorCategory, index, colorCategories) => <div
										key={index}
										className={styles.colorCategory + (index === colorCategories.length - 1 ? ` ${styles.isLast}` : '')}
									>
										<ul className={styles.colorsList}>
											{filteredColors.map((color, index) => {
												if (colorCategory.id !== color.category) {
													return null;
												}
													
												let hasSides = false;
													
												for (let i = 0; i < color.priceModifiers.length; i++) {
													if (color.priceModifiers[i].sidesCounts.includes(2)) {
														hasSides = true;
															
														break;
													}
												}
													
												if (!hasSides) {
													return null;
												}
													
												return (
													<li key={index}>
														<ColorDetail
															color={color}
															sidesCount={2}
															isSelected={
																orderItem &&
																	orderItem.color &&
																	color.id === orderItem.color.id &&
																	orderItem.color.sidesCount === 2
															}
															handleClick={this.handleSelectColor}
														/>
													</li>
												);
											})}
										</ul>
									</div>
									)}
								</div> :
								null}
						</div>
						
						<div className={styles.needHelpLink}>
							<a href={`https://www.${baseUrl}/content/${swatchLink}`} target="_blank">
								{i18n.translate `ColorStep - color swatch`}
							</a>
						</div>
						
						<ButtonGroup align="center">
							<Button
								type="flat"
								size="large"
								label={i18n.translate `ColorStep - button 1`}
								isDisabled={!(orderItem && orderItem.profile)}
								handleClick={this.handleCloseClick}
							/>
						</ButtonGroup>
					</div>
				</div>
			</section>
		);
	}
	
	handleCloseClick = () => {
		openStepActionStream.push(null);
	};
	
	handleSelectColor = (color, sidesCount) => {
		selectColorActionStream.push({
			id: color.id,
			name: color.name,
			category: color.category,
			sidesCount,
			priceModifiers: color.priceModifiers
		});
	};

	handleSingleSideClick = (event) => {
		event.preventDefault();

		this.setState({sidesCount: 1});
	};

	handleDoubleSideClick = (event) => {
		event.preventDefault();

		this.setState({sidesCount: 2});
	};
}
