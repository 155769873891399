import constants from '../internals/constants';
import urlAppRoot from '../internals/urlAppRoot';
import {RouteStream} from '../libs/Router';
import router from './router';

let origin = constants.CS_CZ;

if (global.location.origin.indexOf('.sk') >= 0) {
	origin = constants.SK_SK;
}

if (global.location.origin.indexOf('.de') >= 0 || global.location.origin.indexOf('.eu') >= 0) {
	origin = constants.DE_DE;
}

if (global.location.origin.indexOf('.at') >= 0) {
	origin = constants.DE_AT;
}

if (global.location.origin.indexOf('.hu') >= 0) {
	origin = constants.HU_HU;
}

let routeStream: RouteStream = router.add(`${urlAppRoot}(/)(:language)(/order)(/:order)(/)`).map((value) => {
	let {language, order} = value;
	let routeString = '';
	
	if (language) {
		routeString += `/${language}`;
	}
	
	if (order) {
		routeString += `/${order}`;
	}
	
	if (!routeString) {
		routeString = '/';
	}
	
	// (global as any).ga('set', 'page', routeString);
	// (global as any).ga('send', 'pageview');
	
	if (typeof window !== 'undefined' && window.history && window.history.state && typeof window.history.state.scroll !== 'undefined') {
		requestAnimationFrame(() => {
			window.scrollTo(0, window.history.state.scroll);
		});
	}
	
	if (language === 'order') {
		language = origin;
	}
	
	if (language !== constants.CS_CZ && language !== constants.SK_SK && language !== constants.DE_DE && language !== constants.DE_AT && language !== constants.HU_HU) {
		order = language;
		language = origin;
	}
	
	return {language, order};
});

(global as any).routeStream = routeStream;

export default routeStream;
