import Stream from '../libs/Stream';


export interface SelectAccessoriesAction {
  sunblind?: {
    color?: string;
    price?: number;
  } | null;
  innerWindowsill?: {
    width?: number;
    depth?: number;
    color?: string;
    price?: number;
  } | null;
  outerWindowsill?: {
    width?: number;
    depth?: number;
    color?: string;
    price?: number;
  } | null;
  accessory?: {
    id: number;
    name: string;
    price: number;
    count: number;
    categoryId: number;
  };
}

let selectAccessoriesActionStream: Stream<SelectAccessoriesAction> = new Stream() as Stream<SelectAccessoriesAction>;

export default selectAccessoriesActionStream;
