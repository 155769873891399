// import Inferno from 'inferno';
// import Component from 'inferno-component';
import React from 'react';

import styles from './Select.css';

const ENTER_KEY_CODE = 13;

export interface SelectProps {
  type: 'text' | 'email';
  id?: string;
  name?: string;
  autocomplete?: boolean;
  value?: string | number;
  values?: Array<string | number>;
  unit?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  handleChange?: (value: any) => void;
  handleSave?: (value: any) => void;
  validator?: (value: any) => void;
}

export default class Select extends React.Component<SelectProps, {}> {
	render() {
		if (!this.props) {
			return null;
		}

		return (
			<span className={styles.root}>
				<select
					className={
						styles.default +
            (this.props && this.props.isValid ? ' isValid' : '') +
            (this.props && this.props.isInvalid ? ' isInvalid' : '') +
            (this.props && this.props.isDisabled ? ' isDisabled' : ' isEnabled')
					}
					name={this.props ? this.props.name || this.props.id : ''}
					id={this.props ? this.props.id || this.props.name : ''}
					disabled={this.props ? this.props.isDisabled : false}
					onChange={this.handleInput}
				>
					<option value="placeholder" hidden>
            ...
					</option>
					{this.props.values ?
						this.props.values.map((value, i) => <option key={i} value={value}>
							{value}
						</option>
						) :
						null}
				</select>
				{this.props && this.props.unit ? <span className={styles.unit}>{this.props.unit}</span> : null}
			</span>
		);
	}

  handleInput = (event) => {
  	if (this.props && this.props.handleChange && event.target.value !== 'placeholder') {
  		this.props.handleChange(this.validate(event.target.value));
  	}
  };

  handleFocusOut = (event) => {
  	if (this.props && this.props.handleSave) {
  		this.props.handleSave(this.validate(event.target.value));
  	}
  };

  handleKeyDown = (event) => {
  	if (event.keyCode === ENTER_KEY_CODE) {
  		if (this.props && this.props.handleSave) {
  			this.props.handleSave(this.validate(event.target.value));
  		}
  	}
  };

  validate(value) {
  	return this.props && this.props.validator ? this.props.validator(value) : value;
  }
}
